import { gql, useQuery } from "@apollo/client";
import { Card, Page, ResourceItem, ResourceList, TextStyle } from "@shopify/polaris";

export default function Metas() {
    const { data: { metas: { nodes: metas } } = { metas: { nodes: [] } }, loading } = useQuery(gql`{metas{nodes{id entity title}}}`)
    return (
        <Page title="Metas" narrowWidth primaryAction={{ content: 'Add', url: '/settings/metas/new' }} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <Card>
                <ResourceList
                    loading={loading}
                    items={metas}
                    renderItem={item => (<ResourceItem key={item.id} url={`/settings/metas/${item.id}`}>
                        <h3>
                            <TextStyle variation="strong">{item.title}</TextStyle> - {item.entity}
                        </h3>
                    </ResourceItem>)}
                />
            </Card>
        </Page>
    )
}