import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Card, ChoiceList, EmptyState, Filters, Page, Pagination, ResourceItem, ResourceList, Stack, TextField, TextStyle, Thumbnail } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { useQueryParam, StringParam, NumberParam, createEnumParam, ArrayParam, withDefault } from 'use-query-params';
import useDebounce from '../utils/useDebounce';
import { Helmet } from "react-helmet";
import TagField, { TAGS_QUERY_BY_IDS } from '../utils/TagField';
import { ENUM_LABEL_VALUE } from '../utils/ENUM_LABEL_VALUE.query';

const PRODUCTS_QUERY = gql`query ($page: Int, $query: String, $status: [ProductStatus!], $type: [ProductType!], $collection: [ID!], $tag: [ID!]){
    products(page: $page, query: $query, status: $status, type: $type, collection: $collection, tag: $tag) {
      total
      page
      hasNextPage
      nodes {
        ... on Entity {
          id
        }
        ... on Referable {
          reference
        }
        ... on Product {
          type
          name
          brand {
            id
            name
          }
          image {
            id
            url
          }
        }
      }
    }
  }`;

const COLLECTIONS_LIST = gql`query {
    collections {
      nodes {
        value: id
        label: name
      }
    }
  }`

export default function Products() {
    const { data: { __type: { enumValues: productStatuses } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'ProductStatus' } })
    const { data: { __type: { enumValues: productTypes } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'ProductType' } })
    const { data: { collections: { nodes: collections } } = { collections: { nodes: [] } } } = useQuery(COLLECTIONS_LIST)
    const [fetchProducts, { loading, data = { products: { nodes: [], page: 1 } } }] = useLazyQuery(PRODUCTS_QUERY);


    const [page, setPage] = useQueryParam('page', NumberParam);
    const [query, setQuery] = useQueryParam('query', StringParam);
    const [status, setStatus] = useQueryParam('status', withDefault(ArrayParam, []));
    const [type, setType] = useQueryParam('type', withDefault(ArrayParam, []));
    const [tag, setTag] = useQueryParam('tag', withDefault(ArrayParam, []));
    const [collection, setCollection] = useQueryParam('collection', withDefault(ArrayParam, []));

    const { data: { tags: { nodes: tags } } = { tags: { nodes: [] } } } = useQuery(TAGS_QUERY_BY_IDS, { variables: { ids: tag } })

    const debouncedQuery = useDebounce(query, 300)

    const [selected, setSelected] = useState([])

    const clearAll = useCallback(
        () => {
            setPage(undefined)
            setQuery(undefined)
            setStatus([])
            setType([])
            setCollection([])
            setTag([])
        },
        [setPage, setQuery, setStatus, setType, setTag]
    )
    const updateParam = useCallback(
        (callback, value) => {
            setPage(undefined)
            callback(value)
        },
        [setPage]
    )

    const resourceName = {
        singular: 'product',
        plural: 'products',
    };

    const promotedBulkActions = [
        // {
        //     content: 'Edit customers',
        //     onAction: () => console.log('Todo: implement bulk edit'),
        // },
    ];

    const bulkActions = [
        // {
        //     content: 'Add tags',
        //     onAction: () => console.log('Todo: implement bulk add tags'),
        // },
        // {
        //     content: 'Remove tags',
        //     onAction: () => console.log('Todo: implement bulk remove tags'),
        // },
        // {
        //     content: 'Delete customers',
        //     onAction: () => console.log('Todo: implement bulk delete'),
        // },
    ];

    const filters = [
        {
            key: 'status',
            label: 'Status',
            filter: (
                <ChoiceList
                    title='Status'
                    choices={productStatuses}
                    selected={status}
                    onChange={value => updateParam(setStatus, value)}
                    allowMultiple
                    titleHidden
                />
            ),
            shortcut: true,
        },
        {
            key: 'type',
            label: 'Type',
            filter: (
                <ChoiceList
                    title='Type'
                    choices={productTypes}
                    selected={type}
                    onChange={value => updateParam(setType, value)}
                    allowMultiple
                    titleHidden
                />
            ),
            shortcut: true,
        },
        {
            key: 'collection',
            label: 'Collection',
            filter: (
                <ChoiceList
                    title='Collection'
                    choices={collections}
                    selected={collection}
                    onChange={value => updateParam(setCollection, value)}
                    allowMultiple
                    titleHidden
                />
            ),
        },
        {
            key: 'tag',
            label: 'Tagged with',
            filter: (
                <TagField entity="product" value={tag} onChange={value => updateParam(setTag, value)} />
            ),
        },
    ];

    const appliedFilters = [];

    if (!isEmpty(status)) {
        appliedFilters.push({
            key: 'status',
            label: 'Status: ' + status.map(s => productStatuses.find(l => l.value == s)?.label).join(', '),
            onRemove: () => updateParam(setStatus, [])
        });
    }

    if (!isEmpty(type)) {
        appliedFilters.push({
            key: 'type',
            label: 'Type: ' + type.map(t => productTypes.find(l => l.value == t)?.label).join(', '),
            onRemove: () => updateParam(setType, [])
        });
    }

    if (!isEmpty(collection)) {
        appliedFilters.push({
            key: 'collection',
            label: 'Collection: ' + collection.map(t => collections.find(l => l.value == t)?.label).join(', '),
            onRemove: () => updateParam(setCollection, [])
        });
    }

    if (!isEmpty(tag)) {
        appliedFilters.push({
            key: 'tag',
            label: 'Tagged width: ' + tags.map(tag => tag.label).join(', '),
            onRemove: () => updateParam(setTag, [])
        });
    }

    const filterControl = (
        <Filters
            queryValue={query}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={value => updateParam(setQuery, value || undefined)}
            onQueryClear={() => updateParam(setQuery, undefined)}
            onClearAll={clearAll}
        />
    );

    useEffect(() => {
        fetchProducts({
            variables: {
                page,
                query,
                status,
                type,
                collection,
                tag
                // query: debouncedQueryValue,
                // tags: [debouncedtaggedWith]
            }
        })
    }, [page, debouncedQuery, status, type, collection, tag])

    const emptyStateMarkup =
        !debouncedQuery && !appliedFilters.length && !data.products.nodes.length ? (
            <EmptyState
                heading="Add a product started"
                action={{ content: 'Add product', url: '/products/new' }}
                image="/images/empty/products.svg"
            >
                <p>This is where you’ll add products and manage your pricing. You can also import and export your product inventory.</p>
            </EmptyState>
        ) : undefined;

    return (
        <Page
            title="Products"
            primaryAction={{ content: 'Add', url: '/products/new' }}
        >
            <Helmet>
                <title>Products</title>
            </Helmet>
            <Card>
                <Card.Section flush>
                    <ResourceList
                        resourceName={resourceName}
                        items={data.products.nodes}
                        renderItem={renderItem}
                        selectedItems={selected}
                        onSelectionChange={setSelected}
                        promotedBulkActions={promotedBulkActions}
                        bulkActions={bulkActions}
                        loading={loading}
                        totalItemsCount={data.products.total}
                        hasMoreItems
                        showHeader
                        selectable
                        // sortValue={sortValue}
                        // sortOptions={[
                        //     { label: 'Newest update', value: 'DATE_MODIFIED_DESC' },
                        //     { label: 'Oldest update', value: 'DATE_MODIFIED_ASC' },
                        // ]}
                        // onSortChange={(selected) => {
                        //     setSortValue(selected);
                        //     console.log(`Sort option changed to ${selected}.`);
                        // }}
                        filterControl={filterControl}
                        emptyState={emptyStateMarkup}
                        isFiltered={!!appliedFilters.length}
                    />
                </Card.Section>
                <Card.Section>
                    <Stack distribution="center">
                        <Pagination
                            hasPrevious={data.products.page > 1}
                            previousKeys={[74]}
                            previousTooltip="j"
                            onPrevious={() => setPage(data.products.page > 2 ? data.products.page - 1 : undefined)}
                            hasNext={data.products.hasNextPage}
                            nextKeys={[75]}
                            nextTooltip="k"
                            onNext={() => setPage(data.products.page + 1)}
                        />
                    </Stack>
                </Card.Section>
            </Card>
        </Page>
    );

    function renderItem(item) {
        const { id, reference, type, name, image } = item;
        const media = image?.url && <Thumbnail source={image.url} size="medium" />;
        return (
            <ResourceItem
                verticalAlignment="center"
                id={id}
                url={'/products/' + id}
                media={media}
                accessibilityLabel={`View details for ${name}`}
                // shortcutActions={shortcutActions}
                persistActions
            >
                <h3>
                    <TextStyle variation="strong">{name}</TextStyle>
                </h3>
                <div>#{reference} - {type}</div>
            </ResourceItem>
        );
    }

    function disambiguateLabel(key, value) {
        switch (key) {
            case 'tags':
                return `Tagged with ${value}`;
            default:
                return value;
        }
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
}
