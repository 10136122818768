import { gql, useLazyQuery } from "@apollo/client";
import { Card, Page } from "@shopify/polaris";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import useDebounce from "../utils/useDebounce";

const ORDERS_QUERY = gql`query($query: String, $status: [OrderStatus!], $tag: [ID!]) {
    orders(query: $query, status: $status, tag: $tag) {
      total
      nodes {
        id
        reference
        customer {
          name
        }
        shippingAddress {
          city {
            name
          }
        }
      }
    }
  }`

export default function Orders({ status }) {
    const title = 'Orders';

    const [page, setPage] = useQueryParam('page', NumberParam);
    const [query, setQuery] = useQueryParam('query', StringParam);
    const [tag, setTag] = useQueryParam('tag', withDefault(ArrayParam, []));

    const debouncedQuery = useDebounce(query, 300)

    const [fetch, { loading, error, data: { orders: { nodes: orders } } = { orders: { nodes: [] } } }] = useLazyQuery(ORDERS_QUERY);

    useEffect(
        () => {
            fetch({
                variables: {
                    status,
                    page,
                    query,
                    tag,
                }
            })
        },
        [status, page, debouncedQuery, tag]
    )

    return <Page title={title} primaryAction={{ content: 'New Order', url: '/orders/new' }} fullWidth>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        <Card sectioned>
            <pre>{JSON.stringify(orders, null, 2)}</pre>
            {/* <EmptyState
                heading='Your orders will show here'
                action={{ content: 'Create order', url: '/orders/new' }}
                image="/images/empty/orders.svg"
            >
                <p>This is where you’ll fulfill orders, collect payments, and track order progress.</p>
            </EmptyState> */}
        </Card>
    </Page>
}