import { gql, useMutation, useQuery } from "@apollo/client";
import {
  ActionList,
  Banner,
  Button,
  Card,
  ChoiceList,
  Form,
  FormLayout,
  Heading,
  Layout,
  Link,
  Page,
  Popover,
  ResourceItem,
  ResourceList,
  Scrollable,
  Select,
  Sheet,
  SkeletonPage,
  Stack,
  TextContainer,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  MobileCancelMajor,
} from "@shopify/polaris-icons";
import { useField, getValues, useDynamicList } from "@shopify/react-form";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ProductField from "../utils/ProductField";
import { useDebouncedCallback } from "use-debounce";
import numberFormat from "../utils/numberFormat";
import discountFormat from "../utils/discountFormat";
import useForm from "../utils/useForm";
import CustomerField from "../utils/CustomerField";
import useQueryParams from "../utils/useQueryParams";
import { Helmet } from "react-helmet";
import TagField from "../utils/TagField";
import { Events } from "../utils/Events";
import OrderPayments from "./OrderPayments";

const ORDER_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    name
    address
    city {
      id
      name
    }
    postalCode
    phone
  }
  fragment OrderFragment on Order {
    id
    reference
    status
    customer {
      id
      name
      defaultAddress {
        ...AddressFragment
      }
      addresses {
        ...AddressFragment
      }
    }
    shippingAddress {
      ...AddressFragment
    }
    channel {
      id
    }
    items {
      product {
        ... on Entity {
          id
        }
        ... on Product {
          name
          image {
            url
          }
          availableForSale
          inventoryQuantity
          price
        }
      }
      quantity
      reservedQuantity
      price
      total
    }
    discountApplications {
      discount {
        id
        code
      }
      value
    }
    payments {
      id
      amount
      status
      provider {
        id
        name
        group
      }
    }
    subTotal
    discount
    total
  }
`;
const ORDER_MUTATION = gql`
  ${ORDER_FRAGMENT}
  mutation orderMutation($order: OrderInput!, $persist: Boolean, $id: ID) {
    order(persist: $persist, order: $order, id: $id) {
      ...OrderFragment
    }
  }
`;
const ORDER_QUERY = gql`
  ${ORDER_FRAGMENT}
  query orderQuery($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
`;

function OrderItem({ item, field, removeItem, submit }) {
  const onChange = useCallback((value, key) => {
    field[key].onChange(value);
    submit();
  }, [field, submit]);
  const remove = useCallback(() => {
    removeItem();
    submit();
  }, [removeItem, submit]);
  let error;
  // if (item.quantity != item.reservedQuantity) {
  if (!item.product.availableForSale) {
    error = "Product not available for sale";
  } else if ((item.quantity - item.reservedQuantity) > item.product.inventoryQuantity) {
    error = `Quantity not available (available: ${item.product.inventoryQuantity})`;
  }
  // }
  return (
    <Layout sectioned>
      <h3 style={{ marginBottom: "10px" }}>
        <Stack>
          <Stack.Item fill>
            <TextStyle variation="strong">{item.product.name}</TextStyle>
          </Stack.Item>
          <Stack.Item>
            <Button size="slim" onClick={remove}>
              Remove
            </Button>
          </Stack.Item>
        </Stack>
      </h3>
      {field && (
        <Stack alignment="center">
          <Stack.Item>
            <div style={{ width: "10rem" }}>
              {field.price.value !== "" ? (
                <TextField
                  type="number"
                  {...field.price}
                  onChange={(value) => onChange(value, "price")}
                />
              ) : (
                <TextStyle variation="strong">
                  {numberFormat(item.product.price)}
                </TextStyle>
              )}
            </div>
          </Stack.Item>
          <Stack.Item>x</Stack.Item>
          <Stack.Item fill>
            <div style={{ width: "10rem" }}>
              <TextField
                type="number"
                {...field.quantity}
                onChange={(value) => onChange(value, "quantity")}
              />
            </div>
          </Stack.Item>
          <Stack.Item>{numberFormat(item.total)}</Stack.Item>
        </Stack>
      )}
      {error && <TextStyle variation="negative">{error}</TextStyle>}
    </Layout>
  );
}

function AddressSwitcher({ addresses, field, submit }) {
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = <Link onClick={toggleActive}>Change</Link>;

  return (
    <div>
      <Popover active={active} activator={activator} onClose={toggleActive}>
        <ActionList
          items={addresses.map((address) => ({
            content: (
              <TextContainer>
                <TextStyle
                  {...{ variation: address.id === field.value && "subdued" }}
                >
                  {address.name}
                  <br />
                  {address.address}
                  <br />
                  {address.postalCode}, {address.city.name}
                </TextStyle>
              </TextContainer>
            ),
            onAction: () => {
              field.onChange(address.id);
              toggleActive();
              submit();
            },
            disabled: address.id === field.value,
          }))}
        />
      </Popover>
    </div>
  );
}

const DISCOUNTS_QUERY = gql(
  `query orderDiscountsQuery($customer:String,$includeCustomer:Boolean!){discounts(activeOnly:true){total nodes{id name code value}}customerDiscount:discounts(activeOnly:true,customer:$customer)@include(if:$includeCustomer){total nodes{id name code value}}}`
);

function AddDiscount({ customer, setDiscount }) {
  const [sheetActive, setSheetActive] = useState(false);
  const toggleSheetActive = useCallback(
    () => setSheetActive((sheetActive) => !sheetActive),
    []
  );
  const onChange = useCallback(([discount]) => {
    setDiscount(discount);
    toggleSheetActive();
  }, [setDiscount, toggleSheetActive]);
  const {
    data = { discounts: { nodes: [] }, customerDiscount: { nodes: [] } },
  } = useQuery(DISCOUNTS_QUERY, {
    variables: { customer, includeCustomer: !!customer },
  });
  return (
    <div>
      <Link onClick={toggleSheetActive}>Add discount</Link>
      <Sheet open={sheetActive} onClose={toggleSheetActive}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              borderBottom: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.6rem",
              width: "100%",
            }}
          >
            <Heading>Select discounts</Heading>
            <Button
              accessibilityLabel="Cancel"
              icon={MobileCancelMajor}
              onClick={toggleSheetActive}
              plain
            />
          </div>
          <Scrollable style={{ padding: "1.6rem", height: "100%" }}>
            {data.customerDiscount && (
              <ChoiceList
                //   allowMultiple
                title="Customer discounts"
                choices={data.customerDiscount.nodes.map((discount) => ({
                  label: [
                    discountFormat(discount.value),
                    discount.code,
                    discount.name,
                  ].join(" - "),
                  value: discount.id,
                }))}
                selected={[]}
                onChange={onChange}
              />
            )}
            <ChoiceList
              //   allowMultiple
              title="Store discounts"
              choices={data.discounts.nodes.map((discount) => ({
                label: [
                  discountFormat(discount.value),
                  discount.code,
                  discount.name,
                ].join(" - "),
                value: discount.id,
              }))}
              selected={[]}
              onChange={onChange}
            />
          </Scrollable>
          <div
            style={{
              alignItems: "center",
              borderTop: "1px solid #DFE3E8",
              display: "flex",
              justifyContent: "space-between",
              padding: "1.6rem",
              width: "100%",
            }}
          >
            <Button onClick={toggleSheetActive}>Cancel</Button>
            <Button primary onClick={toggleSheetActive}>
              Done
            </Button>
          </div>
        </div>
      </Sheet>
    </div>
  );
}

export function Order({ id, order: $order, refetch }) {
  const { data: { channels: { nodes: channels } } = { channels: { nodes: [] } } } = useQuery(gql`query orderChannelsQuery{channels(active:true){nodes{value:id label:name}}}`)

  const history = useHistory();
  const query = useQueryParams();
  const [order, setOrder] = useState($order);
  const [mutateOrder, { loading: submitting }] = useMutation(
    ORDER_MUTATION
  );
  const { fields: items, addItem, removeItem } = useDynamicList(
    {
      list: (order?.items ?? $order?.items ?? []).map((item) => ({
        product: item.product.id,
        quantity: item.quantity.toString(),
        price: item.price.toString(),
      })),
    },
    (product) => ({ product, quantity: '1', price: "" })
  );

  const reference = $order?.reference ?? order?.reference;
  const title = reference ? `Order #${reference}` : "New order"

  const {
    fields: discountApplications,
    addItem: addDiscountApplication,
    removeItem: removeDiscountApplication,
  } = useDynamicList(
    {
      list: (
        order?.discountApplications ??
        $order?.discountApplications ??
        []
      ).map((discountApplication) => ({
        discount: discountApplication.discount.id,
        value: discountApplication.value,
      })),
    },
    (discount) => ({ discount })
  );

  const {
    fields,
    // dirty,
    // reset,
    submitErrors,
    makeClean,
    // submit,
    setErrors,
  } = useForm({
    fields: {
      status: useField(order?.status ?? $order?.status ?? "draft"),
      customer: useField(order?.customer?.id ?? query.get('customer')),
      shippingAddress: useField(order?.shippingAddress?.id ?? query.get('address')),
      channel: useField(order?.channel?.id),
      items,
      discountApplications,
      tags: useField(useRef(new Array(...(order?.tags ?? $order?.tags ?? []))).current),
      // useField({
      //     validates: [],
      //     dirtyStateComparator: (a, b) => {
      //         return JSON.stringify(a) != JSON.stringify(b)
      //     }
      // })
    },
    // makeCleanAfterSubmit: false,
    async onSubmit(order) {
      return mutate(order);
    },
  });
  const mutate = useCallback(
    (order, persist = false, status) =>
      mutateOrder({
        variables: {
          order: {
            ...order,
            items: order.items.map(({ product, quantity, price }) => ({
              product,
              quantity: parseInt(quantity),
              price: parseFloat(price),
            })),
            status: status ?? order?.status,
          },
          persist,
          id,
        },
      })
        .then((response) => {
          setOrder({ ...response.data.order });
          if (response.data.order.id) {
            if (persist) {
              makeClean();
            }
            if (id !== response.data.order.id) {
              history.replace("/orders/" + response.data.order.id);
            }
          }
          return { status: "success" };
        })
        .catch((error) => {
          setErrors(error.graphQLErrors);
          return { status: "fail", errors: error.graphQLErrors };
        }),
    [history, id, makeClean, mutateOrder, setErrors]
  );

  const submit = useCallback((persist = false, status) => {
    const order = getValues(fields);
    console.log("submit", order);
    return mutate(order, persist, status);
  }, [fields, mutate]);

  const debouncedSubmit = useDebouncedCallback(() => {
    submit();
  }, 300).callback;

  useEffect(() => {
    if (!query.keys().next().done) {
      submit()
    }
  }, [])

  return (
    <Page
      title={title}
      breadcrumbs={[{ content: "Orders", url: "/orders" }]}
      primaryAction={{
        content: "Save",
        onAction: () => submit(true),
        loading: submitting,
      }}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* {
                dirty && <ContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{
                        onAction: () => submit(true),
                        loading: submitting,
                        disabled: false
                    }}
                    discardAction={{
                        onAction: reset,
                        // discardConfirmationModal: true
                    }}
                />
            } */}
      <Form onSubmit={() => submit(true)}>
        <Layout>
          {submitErrors.length > 0 ? (
            <Layout.Section>
              <Banner status="critical">
                <p>There were some issues with your form submission:</p>
                <ul>
                  {submitErrors.map(({ message }, index) => {
                    return <li key={`${message}${index}`}>{message}</li>;
                  })}
                </ul>
              </Banner>
            </Layout.Section>
          ) : null}
          <Layout.Section>
            <Card title="Order details">
              <Card.Section>
                <ProductField
                  {...{
                    items,
                    addItem: (product) => {
                      addItem(product);
                      setImmediate(() => debouncedSubmit());
                    },
                    status: 'active'
                  }}
                />
              </Card.Section>
              <Card.Section flush>
                <ResourceList
                  items={order?.items || []}
                  renderItem={(item, index) => {
                    return (
                      <ResourceItem
                        verticalAlignment="center"
                        key={item.product.id}
                        id={item.product.id}
                        media={item.product.image?.url && <Thumbnail source={item.product.image.url} />}
                      >
                        <OrderItem
                          item={item}
                          field={items.find(
                            (_) => _.product.value === item.product.id
                          )}
                          removeItem={() => removeItem(index)}
                          submit={debouncedSubmit}
                        />
                      </ResourceItem>
                    );
                  }}
                />
                <Layout>
                  <Layout.Section oneThird>
                    {/* <Card.Section title="Notes">
                      <TextField multiline placeholder="Add a note..." />
                    </Card.Section> */}
                  </Layout.Section>
                  <Layout.Section oneThird>
                    <Card.Section>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>Subtotal</td>
                            <td style={{ textAlign: "right" }}>
                              {numberFormat(order?.subTotal || 0)}
                            </td>
                          </tr>
                          {order?.discountApplications?.map(
                            (discountApplication) => (
                              <tr key={discountApplication.discount.id}>
                                <td>{discountApplication.discount.code}</td>
                                <td style={{ textAlign: "right" }}>
                                  {discountFormat(discountApplication.value)}
                                </td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td>
                              {order?.discount ? (
                                <Link
                                  onClick={() => {
                                    removeDiscountApplication(0);
                                    debouncedSubmit();
                                  }}
                                >
                                  Remove discount
                                </Link>
                              ) : (
                                <AddDiscount
                                  customer={order?.customer?.id}
                                  setDiscount={(discount) => {
                                    addDiscountApplication(discount);
                                    debouncedSubmit();
                                  }}
                                />
                              )}
                            </td>
                            <td style={{ textAlign: "right" }}>
                              {numberFormat(-order?.discount || 0)}
                            </td>
                          </tr>
                          <tr>
                            <td>Total</td>
                            <td style={{ textAlign: "right" }}>
                              {numberFormat(order?.total || 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Section>
                    {/* {order?.id && ["cart", "draft"].includes(order?.status) && (
                      <Layout.Section>
                        <Card.Section>
                          <Stack>
                            <Stack.Item fill></Stack.Item>
                            <Stack.Item>
                              <Button
                                primary
                                onClick={() => submit(true, 'open')}
                              >
                                Validate
                              </Button>
                            </Stack.Item>
                          </Stack>
                        </Card.Section>
                      </Layout.Section>
                    )} */}
                  </Layout.Section>
                </Layout>
              </Card.Section>
              {/* <Card.Section title="Invoice"></Card.Section> */}
              {
                order?.id && order?.customer?.id && <OrderPayments {...{ order, setOrder, refetch }} />
              }
            </Card>
            {order.id && <Events {...{ variables: { order: order.id }, dependencies: [order, $order] }} />}
          </Layout.Section>
          <Layout.Section secondary>
            {order?.customer?.id ? (
              <Card>
                <Card.Header
                  title={order.customer.name}
                  actions={[
                    { content: "Edit", url: `/customers/${order.customer.id}` },
                  ]}
                ></Card.Header>
                <Card.Section>Heheheheheee</Card.Section>
                <Card.Section
                  title="Shipping Address"
                  actions={
                    order.customer.addresses.length > 1 && [
                      {
                        content: (
                          <AddressSwitcher
                            addresses={order.customer.addresses}
                            field={fields.shippingAddress}
                            submit={debouncedSubmit}
                          />
                        ),
                      },
                    ]
                  }
                >
                  <TextContainer>
                    <TextStyle variation="subdued">
                      {order.shippingAddress.name}
                      <br />
                      {order.shippingAddress.address}
                      <br />
                      {order.shippingAddress.postalCode},{" "}
                      {order.shippingAddress.city.name}
                    </TextStyle>
                  </TextContainer>
                </Card.Section>
              </Card>
            ) : (
              <Card title="Find or create a customer">
                <Card.Section>
                  <CustomerField
                    {...fields.customer}
                    submit={debouncedSubmit}
                    address={fields.shippingAddress}
                  />
                </Card.Section>
              </Card>
            )}
            <Card title="Channel" subdued>
              <Card.Section>
                <Select options={channels} {...fields.channel} />
              </Card.Section>
            </Card>
            <Card title="Tags" subdued>
              <Card.Section>
                <TagField entity="order" {...fields.tags} />
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Form>
    </Page>
  );
}

export default function OrderPage() {
  const { id } = useParams();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const { refetch } = useQuery(ORDER_QUERY, {
    // fetchPolicy: 'network-only',
    variables: {
      id,
    },
    onCompleted(data) {
      setOrder({ ...data.order });
      // setItems(data.order.items.map(item => ({ product: item.product.id, quantity: item.quantity, price: item.price })))
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  if (!loading) {
    return <Order {...{ id, order, refetch }} />;
  } else {
    return <SkeletonPage primaryAction secondaryActions={2} />;
  }
}
