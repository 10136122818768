import { EmptyState } from '@shopify/polaris';

export default function Error({ title }) {
    return (
        <EmptyState
            heading={title || 'Error'}
            // action={{ content: 'Add transfer' }}
            // secondaryAction={{ content: 'Learn more', url: 'https://help.shopify.com' }}
            // image="/images/error.svg"
        >
            {/* <p>This is an error page</p> */}
        </EmptyState>
    )
}