import { Button, Card, Page, Stack } from "@shopify/polaris";
import { useAuth } from "../Access/Auth.hook";

export default function Home() {
    const auth = useAuth()
    return (
        <Page
        fullWidth
        // breadcrumbs={[{ content: 'Orders', url: '/orders' }]}
        // title="#1085"
        // secondaryActions={[
        //     { content: 'Print' },
        //     { content: 'Unarchive' },
        //     { content: 'Cancel order' },
        // ]}
        // pagination={{
        //     hasPrevious: true,
        //     hasNext: true,
        // }}
        >
            <Stack vertical>
                <Card sectioned title={`Hello ${auth.user.name}`}>
                    <Stack alignment="center">
                        <Stack.Item fill>
                            <p>Buy postage and ship remaining 2 items</p>
                        </Stack.Item>
                        <Button primary>Continue</Button>
                    </Stack>
                </Card>
                <Stack distribution="fill">
                    <Stack.Item>
                        <Card title="Total sales" sectioned actions={[{content: 'View report'}]}>
                            {/* <p>Box</p> */}
                        </Card>
                    </Stack.Item>
                    <Stack.Item>
                        <Card title="Online store sessions" sectioned>
                            {/* <p>Box</p> */}
                        </Card>
                    </Stack.Item>
                    <Stack.Item>
                        <Card title="Returning customer rate" sectioned>
                            {/* <p>Box</p> */}
                        </Card>
                    </Stack.Item>
                </Stack>
            </Stack>
        </Page>
    )
}