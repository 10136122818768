import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, ButtonGroup, Card, ChoiceList, EmptyState, Filters, Page, Pagination, ResourceItem, ResourceList, Stack, TextField, TextStyle, Thumbnail } from "@shopify/polaris";
import { useField } from "@shopify/react-form";
import { useCallback, useEffect, useState } from "react";
import { useQueryParam, BooleanParam, StringParam, NumberParam } from 'use-query-params';

const LOCATIONS_QUERY = gql`query {
    locations {
      total
      nodes {
        value: id
        label: name
        fulfillsOnlineOrders
      }
    }
  }`
const INVENTORY_QUERY = gql`query($location: ID, $page: Int, $available: Boolean) {
    inventoryLevels(location: $location, page: $page, available: $available) {
      total
      hasNextPage
      page
      nodes {
        id
        item {
          id
          product {
            __typename
            ... on Product {
              name
              image {
                url
              }
            }
          }
        }
        available
        location {
            id
            name
        }
      }
    }
  }
  `

const INVENTORY_LEVEL_MUTATION = gql`mutation($id: ID!, $inventoryLevel: InventoryLevelInput!) {
    inventoryLevel(id: $id, inventoryLevel: $inventoryLevel) {
      id
      available
    }
  }
  `

function isEmpty(value) {
    if (Array.isArray(value)) {
        return value.length === 0;
    } else {
        return value === '' || value == null;
    }
}

function InventoryItemField({ id, item, available: _available, currentLocation, location }) {
    const field = useField('0')
    const [disabled, setDisabled] = useState(false)
    const [updateInventory] = useMutation(INVENTORY_LEVEL_MUTATION)
    const [available, setAvailable] = useState(_available.toString())
    const submit = useCallback(
        (adjustment, value) => {
            console.log(adjustment, id, value)
            setDisabled(true)
            updateInventory({
                variables: {
                    id,
                    inventoryLevel: {
                        available: parseInt(value),
                        adjustment
                    }
                }
            }).then(
                (response) => {
                    setDisabled(false)
                    field.onChange('0')
                    setAvailable(response.data.inventoryLevel.available.toString())
                }
            )
        },
        [field, id, updateInventory],
    )
    return (
        <Stack>
            <Stack.Item fill>
                <h3>
                    <TextStyle variation="strong">{item.product.name}</TextStyle>
                </h3>
                <div>{available} {!currentLocation && <TextStyle variation="strong">in {location.name}</TextStyle>}</div>
            </Stack.Item>
            <Stack.Item>
                <TextField type="number" {...field} disabled={disabled} />
            </Stack.Item>
            <Stack.Item>
                <ButtonGroup segmented>
                    <Button onClick={() => submit('add', field.value)}>Add</Button>
                    <Button onClick={() => submit('set', field.value)}>Set</Button>
                </ButtonGroup>
            </Stack.Item>
        </Stack>
    )
}

function InventoryItem({ id, item, available, location, currentLocation }) {
    return (
        <ResourceItem
            verticalAlignment="center"
            media={item.product.image?.url && <Thumbnail source={item.product.image.url} size="medium" />}
        >
            <InventoryItemField {...{ id, item, location, currentLocation, available }} />
        </ResourceItem>
    )
}

export default function Inventory() {
    const { data: { locations: { nodes: locations } } = { locations: { nodes: [] } } } = useQuery(LOCATIONS_QUERY)
    const [fetchInventoryLevels, { data: { inventoryLevels } = { inventoryLevels: { nodes: [], page: 1, hasNextPage: false } }, loading }] = useLazyQuery(INVENTORY_QUERY)

    const [location, setLocation] = useQueryParam('location', StringParam);
    const [available, setAvailable] = useQueryParam('availability', BooleanParam);
    const [page, setPage] = useQueryParam('page', NumberParam);

    useEffect(
        () => {
            fetchInventoryLevels({ variables: { location, available, page } })
        },
        [page, location, available, fetchInventoryLevels]
    )

    const filters = [
        {
            key: 'location',
            label: 'Location',
            filter: (
                <ChoiceList
                    title='Location'
                    titleHidden
                    choices={[
                        { label: 'All locations', value: undefined, key: 'all' },
                        ...locations,
                        // { label: 'Branes', value: '1' },
                        // { label: 'Morocco Mall', value: '2' },
                    ]}
                    selected={[location]}
                    onChange={value => setLocation(value[0])}
                // allowMultiple
                />
            ),
            shortcut: true,
            hideClearButton: true,
        },
        {
            key: 'available',
            label: 'Availability',
            filter: (
                <ChoiceList
                    title='Availability'
                    titleHidden
                    choices={[
                        { label: 'Available', value: true },
                        { label: 'Not Available', value: false },
                    ]}
                    selected={[available]}
                    onChange={value => setAvailable(value[0])}
                // allowMultiple
                />
            ),
            shortcut: true,
        }
    ]

    const appliedFilters = [];
    if (!isEmpty(location)) {
        appliedFilters.push({
            key: 'location',
            label: `In ${locations.find(l => l.value === location)?.label}`,
            onRemove: () => setLocation(undefined)
        });
    }
    if (!isEmpty(available)) {
        appliedFilters.push({
            key: 'available',
            label: available ? 'Available' : 'Not Available',
            onRemove: () => setAvailable(undefined)
        });
    }

    const emptyStateMarkup =
        !appliedFilters.length && !inventoryLevels.length ? (
            <EmptyState
                heading="Keep track of your inventory"
                action={{ content: 'Go to products', url: '/products' }}
                image="/images/empty/inventory.svg"
            >
                <p>When you enable inventory tracking on your products, you can view and adjust their inventory counts here.</p>
            </EmptyState>
        ) : undefined;

    return (
        <Page title="Inventory">
            <Card>
                <Card.Section flush>
                    <ResourceList
                        items={inventoryLevels.nodes}
                        loading={loading}
                        filterControl={
                            <Filters filters={filters} appliedFilters={appliedFilters} />
                        }
                        // totalItemsCount={inventoryLevels.total}
                        // showHeader
                        renderItem={
                            item => InventoryItem({ ...item, currentLocation: location })
                        }
                        emptyState={emptyStateMarkup}
                    />
                </Card.Section>
                <Card.Section>
                    <Stack distribution="center">
                        <Pagination
                            hasPrevious={inventoryLevels.page > 1}
                            previousKeys={[74]}
                            previousTooltip="j"
                            onPrevious={() => setPage(inventoryLevels.page - 1)}
                            hasNext={inventoryLevels.hasNextPage}
                            nextKeys={[75]}
                            nextTooltip="k"
                            onNext={() => setPage(inventoryLevels.page + 1)}
                        />
                    </Stack>
                </Card.Section>
            </Card>
        </Page>
    )
}