import { gql, useMutation, useQuery } from "@apollo/client";
import { JsonForms } from "@jsonforms/react";
import { Card, Checkbox, Form, FormLayout, Layout, Page, Select, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextField } from "@shopify/polaris";
import { asChoiceField, useField, useForm } from "@shopify/react-form";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { ENUM_LABEL_VALUE } from "../utils/ENUM_LABEL_VALUE.query";
import { polarisRenderers, vanillaCells } from "../utils/MetaModal";

const CHANNEL_FRAGMENT = gql`fragment ChannelFragment on Channel{id name active group config}`
const CHANNEL_QUERY = gql`${CHANNEL_FRAGMENT}query($id:ID!){channel(id:$id){...ChannelFragment}}`
const CHANNEL_MUTATION = gql`${CHANNEL_FRAGMENT}mutation($channel:ChannelInput!,$id:ID){channel(channel:$channel,id:$id){...ChannelFragment}}`

const CHANNEL_CONFIGURATIONS = gql(`{channelConfigurations}`)

export default function Channel() {
    const { id } = useParams();
    const history = useHistory();
    const { data: { channel } = { channel: {} }, loading } = useQuery(CHANNEL_QUERY, { variables: { id } })
    const { data: { __type: { enumValues: channelGroups }, loading: channelGroupsLoading } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'ChannelGroup' } })
    const { data: { channelConfigurations } = { paymentProviderConfigurations: {} } } = useQuery(CHANNEL_CONFIGURATIONS)
    const title = channel?.name ?? 'New channel'
    const [mutate, { loading: mutating }] = useMutation(CHANNEL_MUTATION)
    const {
        fields,
        submit
    } = useForm({
        fields: {
            name: useField(channel?.name),
            active: useField(channel?.active),
            group: useField(channel?.group || channelGroups[0]?.value),
            config: useField(channel?.config || {}),
        },
        onSubmit(channel) {
            return mutate({
                variables: {
                    id,
                    channel
                }
            }).then(
                response => {
                    history.replace("/settings/channels/" + response.data.channel.id);
                    return { status: 'success' }
                }
            )
        }
    })

    if (loading || channelGroupsLoading) {
        return (
            <SkeletonPage primaryAction>
                <Helmet>
                    <title>Loading...</title>
                </Helmet>
                <Layout>
                    <Layout.AnnotatedSection
                        title={<SkeletonDisplayText size="medium" />}
                        description={<SkeletonBodyText />}
                    >
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
                <Layout>
                    <Layout.AnnotatedSection
                        title={<SkeletonDisplayText size="medium" />}
                        description={<SkeletonBodyText />}
                    >
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </SkeletonPage>
        )
    }

    return (
        <Page title={title} primaryAction={{ content: 'Save', onAction: submit, loading: mutating, disabled: loading }} breadcrumbs={[{ content: 'Channels', url: '/settings/channels' }]}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Form onSubmit={submit}>
                <Layout>
                    <Layout.AnnotatedSection
                        title="Details"
                        description="Give this channel a short name to make it easy to identify. You’ll see this name in areas like orders and products."
                    >
                        <Card sectioned>
                            <FormLayout>
                                <TextField label="Channel Name" {...fields.name} />
                                <Checkbox label="Active" {...asChoiceField(fields.active)} />
                                <Select
                                    label="Group"
                                    options={channelGroups}
                                    {...fields.group} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                    {channelConfigurations && fields.group.value && channelConfigurations[fields.group.value] && <Layout.AnnotatedSection
                        title="Configuration"
                    >
                        <Card sectioned>
                            <JsonForms
                                schema={channelConfigurations[fields.group.value].control}
                                uischema={channelConfigurations[fields.group.value].ui}
                                data={fields.config.value}
                                onChange={({ errors, data }) => fields.config.onChange(data)}
                                renderers={polarisRenderers}
                                cells={vanillaCells}
                            />
                        </Card>
                    </Layout.AnnotatedSection>}
                </Layout>
            </Form>
        </Page>
    )
}