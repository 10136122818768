import React, { useState, useEffect, useContext, createContext } from "react";
import useLocalStorage from '../utils/useLocalStorage';
import Keykloack from 'keycloak-js'

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
}

export const keycloak = Keykloack('/keycloak.json')

function useProvideAuth() {
    const [authenticated, setAuthenticated] = useState(null);
    const [user, setUser] = useState();
    const [accessToken, setAccessToken] = useLocalStorage('token');
    useEffect(() => {
        keycloak.onTokenExpired = () => {
            console.log('onTokenExpired')
            keycloak.updateToken().then(
                (refreshed) => {
                    if (refreshed) {
                        setAccessToken({
                            token: keycloak.token,
                            refreshToken: keycloak.refreshToken,
                            idToken: keycloak.idToken,
                        })
                    } else {
                        setAccessToken(null)
                        setAuthenticated(null)
                    }
                }
            )
        }
        keycloak.init({
            onLoad: 'login-required',
            useNonce: true,
            enableLogging: true,
            // ...accessToken,
        }).then(
            authenticated => {
                console.log('authenticated', authenticated)
                // keycloak.updateToken(60 * 60 * 24 * 365).then(
                //     () =>
                //         console.log(keycloak.token)
                // )
                setAuthenticated(authenticated)
                if (authenticated) {
                    setAccessToken({
                        token: keycloak.token,
                        refreshToken: keycloak.refreshToken,
                        idToken: keycloak.idToken,
                    })
                    keycloak.loadUserInfo().then(setUser)
                }
            }
        ).catch(
            console.error
        )
        // eslint-disable-next-line
    }, []);

    // Return the user object and auth methods
    return {
        authenticated,
        user,
        accessToken,
        hasRole: role => user.roles.includes(role),
        login: (email, cb) => { setUser({ email }); cb() },
        logout: () => { setAccessToken(null); keycloak.logout(); },
    };
}