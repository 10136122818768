import { Card, DisplayText, Page, ResourceItem, ResourceList, Thumbnail } from "@shopify/polaris";
import { LocationMajor, AppExtensionMinor, PaymentsMajor, ShipmentMajor, ChannelsMajor } from "@shopify/polaris-icons";
import { Helmet } from "react-helmet";

export default function Settings() {
    return <Page title="Settings" narrowWidth>
        <Helmet>
            <title>Settings</title>
        </Helmet>
        <Card>
            <ResourceList
                items={[
                    {
                        url: 'locations',
                        label: 'Locations',
                        icon: LocationMajor
                    },
                    {
                        url: 'metas',
                        label: 'Metas',
                        icon: AppExtensionMinor
                    },
                    {
                        url: 'payments',
                        label: 'Payments',
                        icon: PaymentsMajor
                    },
                    {
                        url: 'shippers',
                        label: 'Shippers',
                        icon: ShipmentMajor
                    },
                    {
                        url: 'channels',
                        label: 'Channels',
                        icon: ChannelsMajor
                    },
                ]}
                renderItem={item => (<ResourceItem
                    id={item.url}
                    url={`/settings/${item.url}`}
                    media={<Thumbnail source={item.icon} size="small" />}
                >
                    <DisplayText>{item.label}</DisplayText>
                </ResourceItem>)}
            />
        </Card>
    </Page>
}