import { Layout, Card, FormLayout, TextField, Button, Form, Page, Frame, Banner, Loading } from '@shopify/polaris';
// import { useCallback, useState } from 'react';
import { notEmpty, useField, useForm } from '@shopify/react-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from './Auth.hook';

export default function Login() {

    const history = useHistory();
    const location = useLocation();
    const auth = useAuth();

    const {
        fields,
        submit,
        submitting,
        // dirty,
        // reset,
        submitErrors,
        // makeClean,
    } = useForm({
        fields: {
            email: useField({
                value: 'aaaa@aaa.aa',
                validates: [
                    notEmpty('Email is required'),
                ],
            }),
            password: useField({
                value: 'aaa',
                validates: [
                    notEmpty('Password is required'),
                ],
            }),
        },
        async onSubmit(form) {
            console.log('Submit', form)
            await new Promise(resolve => { setTimeout(resolve, 1000) })
            const remoteErrors = []; // your API call goes here
            if (remoteErrors.length > 0) {
                return { status: 'fail', errors: remoteErrors };
            }
            let { from } = location.state || { from: { pathname: '/' } };

            auth.login(form, () => {
                history.replace(from);
            });
            return { status: 'success' };
        },
    });

    const errorBanner =
        submitErrors.length > 0 ? (
            <Layout.Section>
                <Banner status="critical">
                    <p>There were some issues with your form submission:</p>
                    <ul>
                        {submitErrors.map(({ message }, index) => {
                            return <li key={`${message}${index}`}>{message}</li>;
                        })}
                    </ul>
                </Banner>
            </Layout.Section>
        ) : null;

    return (
        <Frame>
            {submitting && <Loading />}
            <Form onSubmit={submit}>
                <Page title="Login">
                    <Layout>
                        {errorBanner}
                        <Layout.Section>
                            <Card sectioned>
                                <FormLayout>
                                    <TextField label="Email" type="email" {...fields.email} />
                                    <TextField label="Password" type="password" {...fields.password} />
                                    <Button primary submit>Login</Button>
                                </FormLayout>
                            </Card>
                        </Layout.Section>
                    </Layout>
                </Page>
            </Form>
        </Frame>
        // <Layout sectioned>
        //     <Layout.AnnotatedSection
        //         title="Store details"
        //         description="Shopify and your customers will use this information to contact you."
        //     >
        //         <Card sectioned>
        //             <Form onSubmit={handleSubmit}>
        //                 <FormLayout>
        //                     <TextField type="email" label="Email" value={email} onChange={setEmail} />
        //                     <TextField type="password" label="Password" value={password} onChange={setPassword} />
        //                     <Button primary submit>Login</Button>
        //                 </FormLayout>
        //             </Form>
        //         </Card>
        //     </Layout.AnnotatedSection>
        // </Layout>
    )
}