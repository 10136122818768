import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Autocomplete, Icon, Layout, Spinner, Stack, Tag as UITag, TextContainer } from "@shopify/polaris";
import {
    CirclePlusMinor, SearchMinor
} from '@shopify/polaris-icons';
import React from "react";
import { useCallback, useEffect, useState } from "react";

const TAGS_QUERY = gql`query($entity: TagEntity!, $query: String) {
    tags(entity: $entity, query: $query) {
      total
      nodes {
        value: id
        label
      }
    }
  }`
const TAG_MUTATION = gql`mutation($tag: TagInput!) {
    tag(tag: $tag) {
      id
      label
    }
  }`

const TAG_QUERY = gql`query($id: ID!) {
    tag(id: $id) {
      label
    }
  }`


export const TAGS_QUERY_BY_IDS = gql`query($ids: [ID!]) {
    tags(ids: $ids) {
      total
      nodes {
        value: id
        label
      }
    }
  }`

export function Tag({ id, ...props }) {
    const { data: { tag: { label } } = { tag: { label: undefined } }, loading } = useQuery(TAG_QUERY, { variables: { id }, fetchPolicy: 'cache-first' })
    if (loading)
        return (
            <UITag {...props}><Spinner size="small" /></UITag>
        )
    return (
        <UITag {...props}>{label}</UITag>
    )
}

export default function TagField({ value, onChange, entity }) {

    const [fetch, { data: { tags: { nodes: tags } } = { tags: { nodes: [] } }, loading }] = useLazyQuery(TAGS_QUERY)
    const [mutate] = useMutation(TAG_MUTATION)

    const [query, setQuery] = useState('');

    useEffect(
        () => {
            fetch({ variables: { entity, query } })
        },
        [query]
    )

    const onAdd = useCallback(
        label => {
            mutate({
                variables: {
                    tag: {
                        entity,
                        label
                    }
                }
            }).then(
                response => {
                    onChange([...value, response.data.tag.id])
                }
            )
        },
    )
    const onRemove = useCallback(
        tag => {
            const options = [...value];
            options.splice(options.indexOf(tag), 1);
            onChange(options);
        },
    )

    return (
        <Layout>
            <Layout.Section fullWidth>
                <Autocomplete
                    options={tags}
                    actionBefore={query && !tags.length && {
                        icon: CirclePlusMinor,
                        content: 'Add ' + query,
                        onAction: () => onAdd(query)
                    }}
                    allowMultiple
                    loading={loading}
                    selected={value}
                    onSelect={onChange}
                    textField={
                        <Autocomplete.TextField
                            value={query}
                            onChange={setQuery}
                        />
                    }
                    emptyState={<React.Fragment>
                        <Icon source={SearchMinor} />
                        <div style={{ textAlign: 'center' }}>
                            <TextContainer>Could not find any results</TextContainer>
                        </div>
                    </React.Fragment>}
                />
            </Layout.Section>
            <Layout.Section fullWidth>
                <Stack>
                    {
                        value.map(
                            tag => (<Tag key={tag} id={tag} onRemove={() => onRemove(tag)}>{tag}</Tag>)
                        )
                    }
                </Stack>
            </Layout.Section>
        </Layout>
    )
}