import { gql, useMutation, useQuery } from "@apollo/client";
import { JsonForms } from "@jsonforms/react";
import { Card, Checkbox, Form, FormLayout, Layout, Page, Select, TextField } from "@shopify/polaris";
import { asChoiceField, useField, useForm } from "@shopify/react-form";
import { useHistory, useParams } from "react-router";
import { polarisRenderers, vanillaCells } from "../utils/MetaModal";
import { ENUM_LABEL_VALUE } from "../utils/ENUM_LABEL_VALUE.query";

const PAYMENT_PROVIDER_FRAGMENT = gql`fragment PaymentProviderFragment on PaymentProvider{id name active group config}`
const PAYMENT_PROVIDER_QUERY = gql`${PAYMENT_PROVIDER_FRAGMENT}query($id:ID!){paymentProvider(id:$id){...PaymentProviderFragment}}`
const PAYMENT_PROVIDER_MUTATION = gql`${PAYMENT_PROVIDER_FRAGMENT}mutation($paymentProvider:PaymentProviderInput!,$id:ID){paymentProvider(paymentProvider:$paymentProvider,id:$id){...PaymentProviderFragment}}`

const PAYMENT_PROVIDER_CONFIGURATIONS = gql(`{paymentProviderConfigurations}`)

export default function Payment() {
    const { id } = useParams();
    const history = useHistory();
    const { data: { paymentProvider } = { paymentProvider: {} }, loading } = useQuery(PAYMENT_PROVIDER_QUERY, { variables: { id } })
    const { data: { __type: { enumValues: paymentGroups } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'PaymentGroup' } })
    const { data: { paymentProviderConfigurations } = { paymentProviderConfigurations: {} } } = useQuery(PAYMENT_PROVIDER_CONFIGURATIONS)
    const [mutate, { loading: mutating }] = useMutation(PAYMENT_PROVIDER_MUTATION)
    const {
        fields,
        submit
    } = useForm({
        fields: {
            name: useField(paymentProvider?.name),
            active: useField(paymentProvider?.active),
            group: useField(paymentProvider?.group || paymentGroups[0]?.value),
            config: useField(paymentProvider?.config || {}),
        },
        onSubmit(paymentProvider) {
            return mutate({
                variables: {
                    id,
                    paymentProvider
                }
            }).then(
                response => {
                    history.replace("/settings/payments/" + response.data.paymentProvider.id);
                    return { status: 'success' }
                }
            )
        }
    })

    return (
        <Page title="Payment Provider" primaryAction={{ content: 'Save', onAction: submit, loading: mutating, disabled: loading }} breadcrumbs={[{ content: 'Payments', url: '/settings/payments' }]}>
            <Form onSubmit={submit}>
                <Layout>
                    <Layout.AnnotatedSection
                        title="Details"
                    // description="Give this location a short name to make it easy to identify. You’ll see this name in areas like orders and products."
                    >
                        <Card sectioned>
                            <FormLayout>
                                <TextField label="Payment Provider Name" {...fields.name} />
                                <Checkbox label="Active" {...asChoiceField(fields.active)} />
                                <Select
                                    label="Group"
                                    options={paymentGroups}
                                    {...fields.group} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                    {paymentProviderConfigurations[fields.group.value] && <Layout.AnnotatedSection
                        title="Configuration"
                    >
                        <Card sectioned>
                            <JsonForms
                                schema={paymentProviderConfigurations[fields.group.value].control}
                                uischema={paymentProviderConfigurations[fields.group.value].ui}
                                data={fields.config.value}
                                onChange={({ errors, data }) => fields.config.onChange(data)}
                                renderers={polarisRenderers}
                                cells={vanillaCells}
                            />
                        </Card>
                    </Layout.AnnotatedSection>}
                </Layout>
            </Form>
        </Page>
    )
}