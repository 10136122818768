import { gql, useMutation } from "@apollo/client";
import { Button, ButtonGroup, Card, DropZone, Icon, Spinner, Stack, Thumbnail } from "@shopify/polaris";
import { CircleCancelMajor, CircleLeftMajor, CircleRightMajor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";

const FILE_MUTATION = gql`mutation file($file:FileInput!){file(file:$file){id presigned}}`;
const IMAGE_MUTATION = gql`mutation image($image:ImageInput!){image(image:$image){id url}}`;

export default function ImagesForm({ value, onChange }) {
    const [createFile] = useMutation(FILE_MUTATION)
    const [createImage] = useMutation(IMAGE_MUTATION)
    // const [images, setImages] = useState(_images);
    const [files, setFiles] = useState([]);
    const handleDropZoneDrop = useCallback(
        (_dropFiles, acceptedFiles, _rejectedFiles) =>
            setFiles((files) => [...files, ...acceptedFiles.map(file => { file.loading = true; return file; })]),
        [],
    );
    useEffect(
        () => {
            if (files.length) {
                const file = files[0]
                console.log('upload file', file.name)
                createFile({
                    variables: {
                        file: {
                            name: file.name,
                            size: file.size,
                            type: file.type
                        }
                    }
                }).then(
                    response => {
                        const body = new FormData()
                        for (const [key, value] of Object.entries(response.data.file.presigned.formData)) {
                            body.append(key, value)
                        }
                        body.append('file', file)
                        fetch(
                            response.data.file.presigned.postURL,
                            {
                                method: 'POST',
                                body
                            }
                        ).then(
                            () => {
                                createImage({
                                    variables: {
                                        image: {
                                            file: response.data.file.id
                                        }
                                    }
                                }).then((response) => {
                                    console.log(JSON.stringify(response.data.image, null, 2))
                                    const waitForFile = setInterval(
                                        () => {
                                            fetch(
                                                response.data.image.url,
                                                {
                                                    method: 'HEAD'
                                                }
                                            ).then(
                                                ({ status }) => {
                                                    if (status === 200) {
                                                        clearInterval(waitForFile)
                                                        onChange([...value, response.data.image.id])
                                                        // onChange([...value, Object.assign(file, value, response.data.image)])
                                                        files.shift()
                                                        file.loading = false
                                                        setFiles([...files])
                                                    }
                                                }
                                            )
                                        },
                                        2000
                                    )
                                })
                            }
                        )
                    }
                )
            }
        },
        [files]
    )

    const removeImage = useCallback(
        index => {
            const images = [...value]
            images.splice(index, 1)
            onChange(images)
        },
    )

    const moveImage = useCallback(
        (index, direction) => {
            const images = [...value]
            images.splice(index + direction, 0, ...images.splice(index, 1))
            onChange(images)
        },
    )

    return (
        <Stack>
            {value.concat(files).map((image, index, array) => (
                <Stack.Item alignment="center" key={index}>
                    <Card>
                        <Thumbnail
                            size="8rem"
                            source={
                                image instanceof File ? window.URL.createObjectURL(image) : `https://storage.ebotiqa.com/606afa86a80831256d9c8c1c/images/${image}`
                            }
                        />
                        <ButtonGroup segmented>
                            <Button disabled={!index} onClick={() => moveImage(index, -1)} size="slim"><Icon source={CircleLeftMajor} /></Button>
                            <Button size="slim" onClick={() => removeImage(index)}>{image.loading ? <Spinner size="small" /> : <Icon source={CircleCancelMajor} />}</Button>
                            <Button disabled={index == array.length - 1} onClick={() => moveImage(index, 1)} size="slim"><Icon source={CircleRightMajor} /></Button>
                        </ButtonGroup>
                    </Card>
                </Stack.Item>
            ))}
            <Stack.Item>
                <div style={{ width: '8rem', height: '8rem' }}>
                    <DropZone accept="image/*" type="image" onDrop={handleDropZoneDrop}>
                        <DropZone.FileUpload />
                    </DropZone>
                </div>
            </Stack.Item>
        </Stack>
    )
}