import { gql, useQuery } from "@apollo/client";
import { Card, Heading, Layout, Page, ResourceItem, ResourceList, TextStyle, Thumbnail } from "@shopify/polaris";
import { LocationMajor } from "@shopify/polaris-icons";
import { Helmet } from "react-helmet";

export default function Locations() {
    const title = 'Locations'
    const { data: { locations: { nodes: locations } } = { locations: { nodes: [] } }, loading } = useQuery(gql`query{locations{nodes{id name address city country postalCode}}}`)
    return (
        <Page title={title} primaryAction={{ content: 'Add', url: '/settings/locations/new' }} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Layout>
                <Layout.AnnotatedSection
                    title={title}
                    description="Manage the places you stock inventory, fulfill orders, and sell products."
                >
                    <Card>
                        <ResourceList
                            items={locations}
                            loading={loading}
                            renderItem={
                                item => (<ResourceItem url={`/settings/locations/${item.id}`} key={item.id} media={<Thumbnail source={LocationMajor} />}>
                                    <Heading><TextStyle keyvariation="strong">{item.name}</TextStyle></Heading>
                                    <p>{[item.address, item.city, item.country, item.postalCode].filter(Boolean).join(', ')}</p>
                                </ResourceItem>)
                            }
                        />
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}