import { gql, useQuery } from "@apollo/client";
import { Card, EmptyState, Heading, Layout, Page, ResourceItem, ResourceList, TextStyle, Thumbnail } from "@shopify/polaris";
import { BalanceMajor, BankMajor, CapturePaymentMinor, CreditCardMajor, GiftCardMajor, PointOfSaleMajor } from "@shopify/polaris-icons";
import { Helmet } from "react-helmet";
import {ENUM_LABEL_VALUE} from "../utils/ENUM_LABEL_VALUE.query";

const icons = {
    cmi: CreditCardMajor,
    cashOnDelivery: CapturePaymentMinor,
    bankTransfer: BankMajor,
    pointOfSale: PointOfSaleMajor,
    wallet: BalanceMajor,
    giftCard: GiftCardMajor,
}

export default function Payments() {
    const title = 'Payment Providers'
    const { data: { __type: { enumValues: paymentGroups } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'PaymentGroup' } })
    const { data: { paymentProviders: { nodes: paymentProviders } } = { paymentProviders: { nodes: [] } }, loading } = useQuery(gql`{paymentProviders{nodes{id name group}}}`)
    return (
        <Page title={title} primaryAction={{ content: 'Add', url: '/settings/payments/new' }} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Layout>
                <Layout.AnnotatedSection
                    title={title}
                    description="Accept payments on your store using providers like CMI, Cash on Delivery, PayPal, or other payment methods."
                >
                    <Card>
                        <ResourceList
                            items={paymentProviders}
                            loading={loading}
                            renderItem={
                                paymentProvider => (<ResourceItem url={`/settings/payments/${paymentProvider.id}`} key={paymentProvider.id} media={<Thumbnail source={icons[paymentProvider.group]} />}>
                                    <Heading><TextStyle keyvariation="strong">{paymentProvider.name}</TextStyle></Heading>
                                    <p>{paymentGroups.find(paymentGroup => paymentProvider.group == paymentGroup.value).label}</p>
                                </ResourceItem>)
                            }
                            emptyState={
                                <EmptyState
                                    heading="Add your Payment Provider"
                                    action={{ content: 'Add', url: '/settings/payments/new' }}
                                    fullWidth
                                />
                            }
                        />
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}