import { gql, useMutation, useQuery } from "@apollo/client";
import { Card, Checkbox, Form, FormLayout, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextField } from "@shopify/polaris";
import { asChoiceField, useField, useForm } from "@shopify/react-form";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";

const LOCATION_FRAGMENT = gql`fragment LocationFragment on Location{id name fulfillsOnlineOrders address city country postalCode phone email}`
const LOCATION_QUERY = gql`${LOCATION_FRAGMENT}query($id:ID!){location(id:$id){...LocationFragment}}`
const LOCATION_MUTATION = gql`${LOCATION_FRAGMENT}mutation($location:LocationInput!,$id:ID){location(location:$location,id:$id){...LocationFragment}}`

export default function Location() {
    const { id } = useParams();
    const history = useHistory();
    const { data: { location } = { location: {} }, loading } = useQuery(LOCATION_QUERY, { variables: { id } })
    const title = location.name ?? 'New location'
    const [mutate, { loading: mutating }] = useMutation(LOCATION_MUTATION)
    const {
        fields,
        submit
    } = useForm({
        fields: {
            name: useField(location?.name),
            fulfillsOnlineOrders: useField(location?.fulfillsOnlineOrders),
            address: useField(location?.address),
            city: useField(location?.city),
            country: useField(location?.country),
            postalCode: useField(location?.postalCode),
            phone: useField(location?.phone),
            email: useField(location?.email),
        },
        onSubmit(location) {
            return mutate({
                variables: {
                    id,
                    location
                }
            }).then(
                response => {
                    history.replace("/settings/locations/" + response.data.location.id);
                    return { status: 'success' }
                }
            )
        }
    })

    if (loading) {
        return (
            <SkeletonPage primaryAction>
                <Helmet>
                    <title>Loading...</title>
                </Helmet>
                <Layout>
                    <Layout.AnnotatedSection
                        title={<SkeletonDisplayText size="medium" />}
                        description={<SkeletonBodyText />}
                    >
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
                <Layout>
                    <Layout.AnnotatedSection
                        title={<SkeletonDisplayText size="medium" />}
                        description={<SkeletonBodyText />}
                    >
                        <Card sectioned>
                            <SkeletonBodyText />
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </SkeletonPage>
        )
    }

    return (
        <Page title={title} primaryAction={{ content: 'Save', onAction: submit, loading: mutating, disabled: loading }} breadcrumbs={[{ content: 'Locations', url: '/settings/locations' }]}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Form onSubmit={submit}>
                <Layout>
                    <Layout.AnnotatedSection
                        title="Details"
                        description="Give this location a short name to make it easy to identify. You’ll see this name in areas like orders and products."
                    >
                        <Card sectioned>
                            <FormLayout>
                                <TextField label="Location Name" {...fields.name} />
                                <Checkbox label="Fulfill online orders from this location" {...asChoiceField(fields.fulfillsOnlineOrders)} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Address"
                    >
                        <Card sectioned>
                            <FormLayout>
                                <TextField label="Address" {...fields.address} />
                                <TextField label="City" {...fields.city} />
                                <TextField label="Country" {...fields.country} />
                                <TextField label="Postal code" {...fields.postalCode} />
                                <TextField label="Phone" {...fields.phone} />
                                <TextField label="Email" {...fields.email} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </Form>
        </Page>
    )
}