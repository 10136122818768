import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Card, EmptyState, Filters, Page, ResourceItem, ResourceList, Tabs, TextStyle } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrayParam, NumberParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import TagField, { TAGS_QUERY_BY_IDS } from '../utils/TagField';
import useDebounce from '../utils/useDebounce';

const CUSTOMERS_QUERY = gql`query($page: Int, $query: String) {
  customers(page: $page, query: $query) {
    total
    nodes {
      id
      reference
      name
      defaultAddress {
          city {
              name
          }
      }
      order {
          id
          reference
      }
    }
  }
}`;

export default function Customers() {

    const [page, setPage] = useQueryParam('page', NumberParam);
    const [query, setQuery] = useQueryParam('query', StringParam);
    const [tag, setTag] = useQueryParam('tag', withDefault(ArrayParam, []));

    const { data: { tags: { nodes: tags } } = { tags: { nodes: [] } } } = useQuery(TAGS_QUERY_BY_IDS, { variables: { ids: tag } })

    const debouncedQuery = useDebounce(query, 300)

    const [selected, setSelected] = useState([])

    const clearAll = useCallback(
        () => {
            setPage(undefined)
            setQuery(undefined)
            setTag([])
        },
        [setPage, setQuery, setTag]
    )
    const updateParam = useCallback(
        (callback, value) => {
            setPage(undefined)
            callback(value)
        },
        [setPage]
    )

    const resourceName = {
        singular: 'customer',
        plural: 'customers',
    };

    const promotedBulkActions = [
    ];

    const bulkActions = [
    ];

    const filters = [
        {
            key: 'tag',
            label: 'Tagged with',
            filter: (
                <TagField entity="customer" value={tag} onChange={value => updateParam(setTag, value)} />
            ),
        },
    ];

    const appliedFilters = [];

    if (!isEmpty(tag)) {
        appliedFilters.push({
            key: 'tag',
            label: 'Tagged width: ' + tags.map(tag => tag.label).join(', '),
            onRemove: () => updateParam(setTag, [])
        });
    }

    const filterControl = (
        <Filters
            queryValue={query}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={value => updateParam(setQuery, value || undefined)}
            onQueryClear={() => updateParam(setQuery, undefined)}
            onClearAll={clearAll}
        />
    );

    const [fetch, { loading, error, data = { customers: { nodes: [] } } }] = useLazyQuery(CUSTOMERS_QUERY);

    useEffect(() => {
        fetch({
            variables: {
                query: debouncedQuery,
                page
            }
        })
    }, [fetch,page, debouncedQuery])

    if (error) {
        return <p>Error</p>
    }

    const emptyStateMarkup =
        !debouncedQuery && !appliedFilters.length && !data.customers.nodes.length ? (
            <EmptyState
                heading="Manage customer details"
                action={{ content: 'Add customer', url: '/customers/new' }}
                image="/images/empty/customers.svg"
            >
                <p>This is where you can manage your customer information and view their purchase history.</p>
            </EmptyState>
        ) : undefined;

    return (
        <Page
            title="Customers"
            fullWidth
            primaryAction={{ content: 'Add', url: '/customers/new' }}
        // secondaryActions={[
        //     {
        //         content: 'Import'
        //     }
        // ]}
        >
            <Card>
                <Tabs tabs={[
                    {
                        id: 'all',
                        content: 'All',
                    },
                    {
                        id: 'new',
                        content: 'New'
                    },
                    {
                        id: 'returning',
                        content: 'Returning'
                    },
                ]} selected={0}>
                    <ResourceList
                        resourceName={resourceName}
                        items={data.customers.nodes}
                        renderItem={renderItem}
                        selectedItems={selected}
                        onSelectionChange={setSelected}
                        promotedBulkActions={promotedBulkActions}
                        bulkActions={bulkActions}
                        loading={loading}
                        totalItemsCount={data.customers.total}
                        hasMoreItems={true}
                        showHeader={true}
                        // sortValue={sortValue}
                        // sortOptions={[
                        //     { label: 'Newest customers', value: 'DATE_MODIFIED_DESC' },
                        //     { label: 'Oldest update', value: 'DATE_MODIFIED_ASC' },
                        // ]}
                        // onSortChange={(selected) => {
                        //     setSortValue(selected);
                        //     console.log(`Sort option changed to ${selected}.`);
                        // }}
                        filterControl={filterControl}
                        emptyState={emptyStateMarkup}
                    />
                </Tabs>
            </Card>
        </Page>
    );

    function renderItem(item) {
        const { id, name } = item;
        // const media = <Avatar customer size="medium" name={name} />;
        // const shortcutActions = latestOrderUrl
        //     ? [{ content: 'View latest order', url: latestOrderUrl }]
        //     : null;
        return (
            <ResourceItem
                id={id}
                url={'/customers/' + id}
                // media={media}
                accessibilityLabel={`View details for ${name}`}
                shortcutActions={[item.order && { content: `Order #${item.order.reference}`, url: `/orders/${item.order.id}` }].filter(Boolean)}
                persistActions
            >
                <h3>
                    <TextStyle variation="strong">{name}</TextStyle>
                </h3>
                <div>{item.defaultAddress?.city?.name}</div>
            </ResourceItem>
        );
    }

    function isEmpty(value) {
        if (Array.isArray(value)) {
            return value.length === 0;
        } else {
            return value === '' || value == null;
        }
    }
}
