import { Spinner } from '@shopify/polaris'
import { lazy, Suspense } from 'react'

const LazyEditor = lazy(() => import('./LazyEditor'))

export default function AsyncEditor({ value, onChange }) {
    return (
        <Suspense fallback={<Spinner size="large" />}>
            <LazyEditor {...{ value, onChange }} />
        </Suspense>
    )
}