import { useCallback, useEffect, useRef } from "react";

export default function BlockEditor({ data, onChange }) {
  const ref = useRef()
  const message = useCallback(
    event => {
      const source = ref.current.contentWindow || ref.current.contentDocument.defaultView
      if (source == event.source) {
        const { data: _data, height, ready } = event.data
        if (_data)
          onChange(_data)
        if (height)
          ref.current.style.minHeight = `${height}px`
        if(ready)
          event.source.postMessage(data, "*")
      }
    }
  )
  useEffect(
    () => {
      window.addEventListener("message", message);
      return () => window.removeEventListener("message", message)
    },
    [message]
  )
  return (
    <iframe ref={ref} src={`/editor.html`} style={{ width: '100%' }} frameBorder="0" />
  )
}