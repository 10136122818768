import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { Card, DataTable, Heading, Link, ResourceItem, ResourceList, Stack, Subheading, TextContainer } from "@shopify/polaris";
import ReactMarkdown from 'react-markdown'
import isSameDay from 'date-fns/isSameDay'
import groupBy from 'lodash.groupby'
import { useEffect } from "react";
import { Timeline } from "rsuite";


const EVENTS_QUERY = gql`query($customer: ID, $order: ID) {
    events(customer: $customer, order: $order) {
      ... on Event {
        timestamp
        type
        user
        message
      }
      ... on UpdateEvent {
        fields
        before
        after
      }
    }
  }`

const urls = {
    c: '/customers/',
    o: '/orders/',
}

export function Events({ variables, dependencies = [] }) {
    const [fetch, { data: { events } = { events: [] } }] = useLazyQuery(EVENTS_QUERY)

    useEffect(
        () => {
            fetch({ variables })
        },
        dependencies
    )

    // return (
    //     <Card title="Events" sectioned subdued>
    //         <pre>{JSON.stringify(events, null, 2)}</pre>
    //     </Card>
    // )

    return (
        <Card title="Events" sectioned subdued>
            <Timeline className="reversed">
                {
                    Object.entries(groupBy(
                        events,
                        event => new Intl.DateTimeFormat('MA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(event.timestamp))
                    )).reverse().map(
                        ([date, events]) => {
                            return [
                                ...events.reverse().map(
                                    (event, index) => (
                                        <Timeline.Item id={event.id}>
                                            <Stack wrap={false}>
                                                <Stack.Item>
                                                    {new Intl.DateTimeFormat('default', { timeStyle: 'short', hour12: false }).format(new Date(event.timestamp))}
                                                </Stack.Item>
                                                <Stack.Item fill>
                                                    <ReactMarkdown
                                                        transformLinkUri={(href) => {
                                                            const [type, id] = href.split(':')
                                                            return urls[type] + id
                                                        }}
                                                        components={{
                                                            a({ href, children }) {
                                                                return <Link url={href}>{children}</Link>
                                                            },
                                                            details() {
                                                                console.log('Hii')
                                                                return <Heading>Hello details</Heading>
                                                            }
                                                        }}
                                                    >{event.message}</ReactMarkdown>
                                                    {/* {
                                                        event.fields && <Card>
                                                            <DataTable
                                                                headings={['key', 'before', 'after']}
                                                                columnContentTypes={['text', 'text', 'text']}
                                                                rows={event.fields.map(
                                                                    field => ([field, JSON.stringify(event.before[field], null, 2), JSON.stringify(event.after[field], null, 2)])
                                                                )}
                                                            />
                                                        </Card>
                                                    } */}
                                                </Stack.Item>
                                            </Stack>
                                        </Timeline.Item>
                                    )
                                )
                                ,
                                <Timeline.Item key={date} dot=" ">
                                    <Heading>{new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(new Date(date))}</Heading>
                                </Timeline.Item>
                            ]
                        }
                    )
                }
            </Timeline>
        </Card>
    )

    return (
        <Card title="Events" sectioned subdued>
            <Timeline className="reversed">
                {[
                    <Timeline.Item dot=" ">Your order starts processing.</Timeline.Item>,
                    <Timeline.Item>Your order to be ready for delivery</Timeline.Item>
                ]}
                {/* <Timeline.Item>Your parcel has been out of the library</Timeline.Item>
                <Timeline.Item>Send to Shanghai Hongkou Company</Timeline.Item>
                <Timeline.Item>Sending you a piece</Timeline.Item> */}
            </Timeline>
        </Card>
    )

    return <Card title="Events" subdued>
        {
            Object.entries(groupBy(
                events,
                event => new Intl.DateTimeFormat('MA', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(event.timestamp))
            )).map(
                ([date, events]) => {
                    return <div key={date}>
                        <Card.Header title={new Intl.DateTimeFormat('default', { dateStyle: 'long' }).format(new Date(date))} />
                        <ResourceList
                            items={events}
                            renderItem={
                                (event, id, index) => <ResourceItem>
                                    <Stack wrap={false}>
                                        <Stack.Item>
                                            {new Intl.DateTimeFormat('default', { timeStyle: 'short', hour12: false }).format(new Date(event.timestamp))}
                                        </Stack.Item>
                                        <Stack.Item fill>
                                            <ReactMarkdown
                                                transformLinkUri={(href) => {
                                                    const [type, id] = href.split(':')
                                                    return urls[type] + id
                                                }}
                                                components={{
                                                    a({ href, children }) {
                                                        return <Link url={href}>{children}</Link>
                                                    }
                                                }}
                                            >{event.message}</ReactMarkdown>
                                        </Stack.Item>
                                    </Stack>
                                </ResourceItem>
                            }
                        />
                    </div>
                }
            )
        }
        {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
    </Card>
}