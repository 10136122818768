import { useQuery } from "@apollo/client";
import {
  Card,
  EmptyState,
  Page,
  ResourceItem,
  ResourceList,
  TextStyle,
} from "@shopify/polaris";
import gql from "graphql-tag";
import useQueryParams from '../utils/useQueryParams'

const COLLECTIONS_QUERY = gql`
  fragment collectionFragment on Collection {
    id
    name
    description
  }
  query($parentsOnly: Boolean, $parent: ID!, $includeParent: Boolean!) {
    collections(limit: 0, parentsOnly: $parentsOnly, parent: $parent) {
      hasNextPage
      nodes {
        ...collectionFragment
      }
    }
    parent: collection(id: $parent) @include(if: $includeParent) {
      ...collectionFragment
      parent {
        ...collectionFragment
      }
    }
  }
`;

export default function Collections() {
  const query = useQueryParams();
  const { data, loading } = useQuery(COLLECTIONS_QUERY, {
    variables: {
      parent: query.get("parent") || '',
      parentsOnly: !query.get("parent"),
      includeParent: !!query.get("parent"),
      // id: query.get('parent'),
    },
  });

  const emptyStateMarkup =
      (
          <EmptyState
              heading="Group your products into categories"
              action={{ content: 'Create collection', url: '/collections/new' }}
              image="/images/empty/collections.svg"
          >
              <p>Use collections to organize your products into categories and galleries for your online store.</p>
          </EmptyState>
      );

  return (
    <Page
      title={data?.parent ? `Subcollections of ${data?.parent.name}` : 'Collections'}
      primaryAction={{ content: "Add", url: `/collections/new${data?.parent ? '?parent='+data.parent.id : ''}` }}
      breadcrumbs={
        data?.parent ? [
            data?.parent?.parent ? {
                content: data.parent.parent.name,
                url: `/collections?parent=${data.parent.parent.id}`
            } :
            {
                content: 'Collections',
                url: `/collections`
            }
        ] : []
      }
    >
      <Card>
        <ResourceList
          loading={loading}
          items={data?.collections?.nodes || []}
          renderItem={(collection) => (
            <ResourceItem
              id={collection.id}
              url={`/collections?parent=${collection.id}`}
              shortcutActions={[
                {
                  content: 'Edit',
                  url: `/collections/${collection.id}`,
                }
              ]}
            >
              <h3>
                <TextStyle variation="strong">{collection.name}</TextStyle>
              </h3>
              <p>{collection.description}</p>
            </ResourceItem>
          )}
          emptyState={emptyStateMarkup}
        />
      </Card>
    </Page>
  );
}
