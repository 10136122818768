import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, Card, Collapsible, ResourceItem, ResourceList, Select, Stack, TextStyle } from "@shopify/polaris";
import { useCallback, useState } from "react";

export default function OrderPayments({ order, setOrder, refetch }) {
    const { data: { paymentProviders: { nodes: paymentProviders } } = { paymentProviders: { nodes: [] } } } = useQuery(gql`query orderPaymentProvidersQuery{paymentProviders(active:true){nodes{value:id label:name}}}`)
    const [paymentProvider, setPaymentProvider] = useState();
    const [addPaymentMutation] = useMutation(gql(`mutation orderAddPaymentMutation($order:ID!,$provider:ID!){payment(payment:{order:$order,provider:$provider}){id}}`))
    const addPaymentCallback = useCallback(
        () => {
            addPaymentMutation({
                variables: {
                    order: order?.id,
                    provider: paymentProvider
                }
            }).then(
                () => {
                    refetch().then(
                        response => {
                            setOrder({ ...response.data.order })
                            setOpen(false)
                        }
                    )
                }
            )
        },
        [order, paymentProvider, addPaymentMutation, refetch]
    )
    const [open, setOpen] = useState(!order.payments.length);
    return (
        <Card.Section title="Payments">
            {/* <Card.Header title="Payments" /> */}
            <Collapsible open={open}>
                <Stack vertical>
                    <Stack.Item>Please choose payment method</Stack.Item>
                    <Stack.Item>
                        <Stack>
                            <Stack.Item fill>
                                <Select options={[{ label: 'Select Payment Provider', value: '' }].concat(paymentProviders)} value={paymentProvider} onChange={setPaymentProvider} />
                            </Stack.Item>
                            <Stack.Item><Button primary onClick={addPaymentCallback}>Confirm</Button></Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Collapsible>
            <ResourceList
                items={order.payments}
                renderItem={
                    payment => <ResourceItem>
                        <h3>
                            <TextStyle variation="strong">{payment.provider.name} ({payment.provider.group})</TextStyle>
                        </h3>
                        <div>{payment.status}</div>
                    </ResourceItem>
                }
            />
        </Card.Section >
    )
}