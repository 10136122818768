import { gql, useQuery } from "@apollo/client";
import { Card, EmptyState, Heading, Layout, Page, ResourceItem, ResourceList, TextStyle, Thumbnail } from "@shopify/polaris";
import { Helmet } from "react-helmet";
import {ENUM_LABEL_VALUE} from "../utils/ENUM_LABEL_VALUE.query";

export default function Shippers() {
    const title = 'Shippers'
    const { data: { __type: { enumValues: shipperGroups } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'ShipperGroup' } })
    const { data: { shippers: { nodes: shippers } } = { shippers: { nodes: [] } }, loading } = useQuery(gql`{shippers{nodes{id name group}}}`)
    return (
        <Page title={title} primaryAction={{ content: 'Add', url: '/settings/shippers/new' }} breadcrumbs={[{ content: 'Settings', url: '/settings' }]}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Layout>
                <Layout.AnnotatedSection
                    title={title}
                    // description="Accept shippers on your store using providers like CMI, Cash on Delivery, PayPal, or other payment methods."
                >
                    <Card>
                        <ResourceList
                            items={shippers}
                            loading={loading}
                            renderItem={
                                shipper => (<ResourceItem url={`/settings/shippers/${shipper.id}`} key={shipper.id} >
                                    <Heading><TextStyle keyvariation="strong">{shipper.name}</TextStyle></Heading>
                                    <p>{shipperGroups.find(shipperGroup => shipper.group == shipperGroup.value).label}</p>
                                </ResourceItem>)
                            }
                            emptyState={
                                <EmptyState
                                    heading="Add your Shipper"
                                    action={{ content: 'Add', url: '/settings/shippers/new' }}
                                    fullWidth
                                />
                            }
                        />
                    </Card>
                </Layout.AnnotatedSection>
            </Layout>
        </Page>
    )
}