import { gql, useMutation, useQuery } from '@apollo/client';
import { Banner, Button, Card, Form, FormLayout, Layout, Loading, Modal, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Stack, TextContainer, TextField, TextStyle, Toast, Tooltip } from "@shopify/polaris";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { useField, useForm } from "@shopify/react-form";
import { useCallback, useState } from "react";
import { CopyToClipboard as RCopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';
import { useParams } from "react-router-dom";
import { Events } from '../utils/Events';

function CopyToClipboard({ ...rest }) {
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const toastMarkup = active ? (
        <Toast content="Copied to clipboard" onDismiss={toggleActive} />
    ) : null;

    return (
        <>
            {toastMarkup}
            <RCopyToClipboard {...rest} onCopy={toggleActive}></RCopyToClipboard>
        </>
    )
}

const CUSTOMER_FRAGMENT = gql`fragment CustomerFragment on Customer {id reference name firstName lastName phone email defaultAddress{id name firstName lastName phone email address company city{id name}postalCode}addresses{id name firstName lastName phone email address company city{id name}postalCode}}`

const CUSTOMER_QUERY = gql`${CUSTOMER_FRAGMENT} query getCustomer($id:ID!){customer(id:$id){...CustomerFragment}}`

const UPDATE_CUSTOMER_MUTATION = gql`mutation updateCustomer($customer: CustomerInput!, $id: ID!) {
    customer(customer: $customer, id: $id) {
      id
    }
  }
`
const ADDRESS_MUTATION = gql`mutation address($address:AddressInput!,$id:ID){address(address:$address,id:$id){id}}`

function Address({ customer, refetch, address = {}, active, toogleActive }) {
    const [mutateAddress, { loading }] = useMutation(ADDRESS_MUTATION);
    const {
        fields,
        submit,
        dirty,
        reset,
        submitErrors,
    } = useForm({
        fields: {
            firstName: useField(address.firstName || customer.firstName),
            lastName: useField(address.lastName || customer.lastName),
            company: useField(address.company),
            email: useField(address.email || customer.email),
            phone: useField(address.phone || customer.phone),
            address: useField(address.address),
            city: useField(address.city?.id ?? address.city?.name),
            postalCode: useField(address.postalCode),
        },
        onSubmit: async values => {
            return mutateAddress({
                variables: {
                    address: {
                        ...values,
                        customer: customer.id
                    },
                    id: address.id
                }
            }).then(
                () => {
                    refetch()
                    toogleActive()
                    return { status: 'success' }
                }
            ).catch(
                error => {
                    return { status: 'fail', errors: [error] }
                }
            )
        },
    });

    return (
        <Modal open={active} onClose={toogleActive} title={address ? 'Edit address' : 'Add new address'} primaryAction={{
            content: 'Save',
            disabled: !dirty,
            onAction: submit,
            loading
        }}
            secondaryActions={[
                {
                    content: 'Cancel',
                    onAction: () => { reset(); toogleActive() },
                },
            ]}>
            <Modal.Section>
                {
                    submitErrors.length > 0 ? (
                        <Layout.Section>
                            <Banner status="critical">
                                <p>There were some issues with your form submission:</p>
                                <ul>
                                    {submitErrors.map(({ message }, index) => {
                                        return <li key={`${message}${index}`}>{message}</li>;
                                    })}
                                </ul>
                            </Banner>
                        </Layout.Section>
                    ) : null
                }
                <Form onSubmit={submit}>
                    <FormLayout>
                        <FormLayout.Group>
                            <TextField label="First name" {...fields.firstName} />
                            <TextField label="Last name" {...fields.lastName} />
                        </FormLayout.Group>
                        <TextField label="Company" {...fields.company} />
                        <TextField type="tel" label="Phone" {...fields.phone} />
                        <TextField label="Address" multiline {...fields.address} />
                        <TextField label="City" {...fields.city} />
                        <TextField label="Postal code" {...fields.postalCode} />
                    </FormLayout>
                </Form>
            </Modal.Section>
        </Modal>
    )
}

function ManageAddress({ customer, refetch, address, updateCustomer }) {
    const [active, setActive] = useState(false);
    const toogleActive = useCallback(() => setActive(!active), [active]);
    const addressMarkup = <Address {...{ customer, refetch, active, toogleActive, address }} />

    const setDefault = useCallback(
        () => updateCustomer({
            variables: {
                customer: {
                    defaultAddress: address.id
                },
                id: customer.id
            }
        }).then(
            () => {
                refetch()
                return { status: 'success' }
            }
        ),
        [address, customer, refetch, updateCustomer]
    )

    return (
        <Card.Section title={customer.defaultAddress.id === address.id && 'Default address'}>
            <Stack vertical>
                <Stack.Item>
                    <TextContainer>
                        {address.name}
                        <br />
                        {address.address}
                        <br />
                        {address.postalCode}, {address.city.name}
                    </TextContainer>
                </Stack.Item>
                <Stack.Item>
                    {addressMarkup}
                    <Stack>
                        <Stack.Item fill>
                            <Button plain onClick={toogleActive}>Edit address</Button>
                        </Stack.Item>
                        {customer.defaultAddress.id !== address.id && <Stack.Item>
                            <Button onClick={setDefault}>Make default</Button>
                        </Stack.Item>}
                    </Stack>
                </Stack.Item>
            </Stack>
        </Card.Section>
    )
}

function ManageAddresses({ customer, refetch, active, toogleActive, updateCustomer }) {

    const [activeAddress, setActiveAddress] = useState(false);
    const toogleActiveAddress = useCallback(() => setActiveAddress(!activeAddress), [activeAddress]);
    const newAddressMarkup = <Address {...{ customer, refetch, active: activeAddress, toogleActive: toogleActiveAddress }} />

    return (
        <Layout>
            <Modal open={active} onClose={toogleActive} title="Manage addresses" primaryAction={{ content: 'Add new address', onAction: toogleActiveAddress }}>
                <Modal.Section flush>
                    {
                        customer.addresses.map(
                            address => (<ManageAddress key={address.id} {...{ customer, refetch, address, updateCustomer }} />)
                        )
                    }
                </Modal.Section>
            </Modal>
            {newAddressMarkup}
        </Layout>
    )
}

function CustomerOverview({ customer, refetch }) {
    const [customerModalActive, setCustomerModalActive] = useState(false);
    const toggleCustomerModal = useCallback(() => setCustomerModalActive(!customerModalActive), [customerModalActive]);
    const [updateCustomer, { loading }] = useMutation(UPDATE_CUSTOMER_MUTATION);

    const {
        fields,
        submit,
        dirty,
        reset,
        submitErrors,
    } = useForm({
        fields: {
            firstName: useField(customer.firstName),
            lastName: useField(customer.lastName),
            email: useField(customer.email),
            phone: useField(customer.phone),
        },
        onSubmit: async values => {
            if (!dirty) {
                toggleCustomerModal()
                return { status: 'success' }
            }
            return updateCustomer({
                variables: {
                    customer: values,
                    id: customer.id
                }
            }).then(
                () => {
                    refetch()
                    toggleCustomerModal()
                    return { status: 'success' }
                }
            ).catch(
                error => {
                    return { status: 'fail', errors: [error] }
                }
            )
        },
    });

    const [activeAddress, setActiveAddress] = useState(false);
    const toogleActiveAddress = useCallback(() => setActiveAddress(!activeAddress), [activeAddress]);
    const newAddressMarkup = <Address {...{ customer, refetch, active: activeAddress, toogleActive: toogleActiveAddress }} />

    const [activeManageAddresses, setManageAddresses] = useState(false);
    const toogleManageAddresses = useCallback(() => setManageAddresses(!activeManageAddresses), [activeManageAddresses]);
    const manageAddressesMarkup = <ManageAddresses {...{ customer, refetch, active: activeManageAddresses, toogleActive: toogleManageAddresses, updateCustomer }} />

    return (
        <Card title="Customer overview" actions={[{ content: 'Edit', onAction: toggleCustomerModal }]}>
            {newAddressMarkup}
            {manageAddressesMarkup}
            <Card>
                <Modal open={customerModalActive} title="Edit customer" onClose={toggleCustomerModal} primaryAction={{
                    content: 'Save',
                    disabled: !dirty,
                    onAction: submit,
                    loading
                }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: () => { reset(); toggleCustomerModal() },
                        },
                    ]}>
                    <Modal.Section>
                        {
                            submitErrors.length > 0 ? (
                                <Layout.Section>
                                    <Banner status="critical">
                                        <p>There were some issues with your form submission:</p>
                                        <ul>
                                            {submitErrors.map(({ message }, index) => {
                                                return <li key={`${message}${index}`}>{message}</li>;
                                            })}
                                        </ul>
                                    </Banner>
                                </Layout.Section>
                            ) : null
                        }
                        <Form onSubmit={submit}>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField label="First name" {...fields.firstName} />
                                    <TextField label="Last name" {...fields.lastName} />
                                </FormLayout.Group>
                                <TextField type="tel" label="Phone" {...fields.phone} />
                                <TextField type="email" label="Email" {...fields.email} />
                            </FormLayout>
                        </Form>
                    </Modal.Section>
                </Modal>
                <Card.Section>
                    {customer.phone ? <Stack>
                        <Stack.Item fill>
                            <Button plain>{customer.phone}</Button>
                        </Stack.Item>
                        <Stack.Item>
                            <Tooltip content="Copy phone">
                                <CopyToClipboard text={customer.phone}>
                                    <Button icon={ClipboardMinor} plain></Button>
                                </CopyToClipboard>
                            </Tooltip>
                        </Stack.Item>
                    </Stack> : <TextContainer><TextStyle variation="subdued">No phone provided.</TextStyle></TextContainer>}
                    {customer.email ? <Stack>
                        <Stack.Item fill>
                            <Button plain>{customer.email}</Button>
                        </Stack.Item>
                        <Stack.Item>
                            <Tooltip content="Copy email">
                                <CopyToClipboard text={customer.email}>
                                    <Button icon={ClipboardMinor} plain></Button>
                                </CopyToClipboard>
                            </Tooltip>
                        </Stack.Item>
                    </Stack> : <TextContainer><TextStyle variation="subdued">No email provided.</TextStyle></TextContainer>}
                </Card.Section>
                <Card.Section title="Default address" actions={[customer.addresses ? { content: 'Manage', onAction: toogleManageAddresses } : { content: 'Add', onAction: toogleActiveAddress }]}>
                    {
                        customer.defaultAddress ? <Stack vertical>
                            <Stack.Item >
                                <TextContainer>
                                    {customer.defaultAddress.name}
                                    <br />
                                    {customer.defaultAddress.address}
                                    <br />
                                    {customer.defaultAddress.postalCode}, {customer.defaultAddress.city.name}
                                </TextContainer>
                            </Stack.Item>
                            <Stack.Item>
                                <Button plain onClick={toogleActiveAddress}>Add new address</Button>
                            </Stack.Item>
                        </Stack> :
                            <TextContainer>
                                <TextStyle variation="subdued">No address provided.</TextStyle>
                            </TextContainer>
                    }
                </Card.Section>
            </Card>
        </Card>
    )
}

export default function Customer() {
    const { id } = useParams();
    const { data, loading, refetch } = useQuery(CUSTOMER_QUERY, { variables: { id } });

    const [refetching, setRefetching] = useState(false);
    const refetchCallback = useCallback(() => {
        setRefetching(true)
        refetch().then(
            () => setRefetching(false)
        )
    }, [setRefetching, refetch])
    if (loading)
        return (
            <SkeletonPage>
                <Layout>
                    <Layout.Section>
                        <Card sectioned>
                            <SkeletonDisplayText size="small" />
                            <SkeletonBodyText />
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card sectioned>
                            <Card.Subsection>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card.Subsection>
                            <Card.Subsection>
                                <TextContainer>
                                    <SkeletonDisplayText size="small" />
                                    <SkeletonBodyText />
                                </TextContainer>
                            </Card.Subsection>
                        </Card>
                    </Layout.Section>
                </Layout>
            </SkeletonPage>
        )

    const { customer } = data;

    return (
        <Page title={customer.name} breadcrumbs={[{ content: 'Customers', url: '/customers' }]}>
            <Helmet>
                <title>{customer.name}</title>
            </Helmet>
            {refetching && <Loading />}
            <Layout>
                <Layout.Section>
                    <Card title="Order details" sectioned actions={[
                        {
                            content: 'Add order',
                            url: `/orders/new?customer=${customer.id}&address=${customer.defaultAddress.id}`
                        }
                    ]}>
                        <p>View a summary of your order.</p>
                    </Card>
                    <Events {...{ variables: { customer: customer.id }, dependencies: [customer] }} />
                </Layout.Section>
                <Layout.Section secondary>
                    <CustomerOverview customer={customer} refetch={refetchCallback} />
                </Layout.Section>
            </Layout>
        </Page>
    )
}