import { gql, useLazyQuery } from "@apollo/client";
import { Autocomplete, Button, Icon, TextContainer, TextStyle } from "@shopify/polaris";
import { PlusMinor, SearchMinor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";
import useDebounce from "./useDebounce";

const CUSTOMERS_QUERY = gql`query ($query: String) {
    customers(query: $query) {
      nodes {
        id
        name
        defaultAddress {
            id
            city {
                name
            }
        }
      }
    }
  }`

function renderOption(customer) {
    return {
        id: customer.id,
        value: customer.id,
        label: <TextContainer spacing="tight">
            <h3>
                <TextStyle variation="strong">{customer.name}</TextStyle>
            </h3>
            <div><TextStyle>{customer.defaultAddress?.city?.name}</TextStyle></div>
        </TextContainer>,
        // media: <Thumbnail source={product.image.url} />,
        // disabled: !product.availableForSale
    }
}

export default function CustomerField({ value, onChange, submit, address }) {
    const [filter, setFilter] = useState('')
    const debouncedFilter = useDebounce(filter, 300)
    const [searchCustomers, { data = {customers: {nodes: []}}, loading }] = useLazyQuery(CUSTOMERS_QUERY)
    const onSelect = useCallback(
        ([customer]) => {
            console.log('customer', customer)
            onChange(customer)
            if(address) {
                address.onChange(data?.customers.nodes.find(_ => _.id === customer).defaultAddress.id)
            }
            submit()
        },
        [address, data, onChange, submit]
    )

    useEffect(
        () => {
            searchCustomers({
                variables: {
                    query: filter
                }
            })
        },
        [debouncedFilter]
    )
    return (
        <Autocomplete
            options={data?.customers.nodes.map(renderOption)}
            selected={[]}
            loading={loading}
            onSelect={onSelect}
            textField={
                <Autocomplete.TextField
                    value={filter}
                    onChange={setFilter}
                    prefix={<Icon source={SearchMinor} color="base" />}
                    placeholder="Search customers"
                    suffix={<Button icon={PlusMinor} plain size="large" onClick={() => console.log('Addd')}></Button>}
                />
            }
        />
        // <TextField
        //     placeholder="Search customers"
        //     value={filter}
        //     onChange={setFilter}
        //     prefix={<Icon source={SearchMinor} color="base" />}
        //     suffix={<Button icon={PlusMinor} plain size="large" onClick={() => console.log('Addd')}></Button>}
        // />
    )
}