import { gql, useLazyQuery } from "@apollo/client";
import { Autocomplete, Icon, TextContainer, TextStyle, Thumbnail } from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";
import { useCallback, useEffect, useState } from "react";
import useDebounce from "./useDebounce";
import numberFormat from '../utils/numberFormat'

const PRODUCTS_QUERY = gql`query($query: String, $type: [ProductType!], $status: [ProductStatus!]) {
    products(query: $query, limit: 10, type: $type, status: $status) {
      total
      nodes {
        ... on Entity {
          id
        }
        ... on Product {
          sku
          name
          image {
            id
            url
          }
          price
          compareAtPrice
          availableForSale
        }
      }
    }
  }
  `

function renderOption(product) {
    return {
        id: product.id,
        value: product.id,
        label: <TextContainer spacing="tight">
            <h3>
                <TextStyle variation="strong">{product.name}</TextStyle>
            </h3>
            <div>{numberFormat(product.price)} - <TextStyle variation={product.availableForSale ? 'positive' : 'negative'}>{product.availableForSale ? 'Available' : 'Not available'}</TextStyle></div>
        </TextContainer>,
        media: product.image?.url && <Thumbnail source={product.image.url} />,
        // disabled: !product.availableForSale
    }
}

export default function ProductField({ items, addItem, type, status }) {
    const [options, setOptions] = useState([])
    const [filter, setFilter] = useState('')
    const debouncedFilter = useDebounce(filter, 300)
    const [searchProducts, { data, loading }] = useLazyQuery(PRODUCTS_QUERY, {variables: {type, status}})

    const onSelect = useCallback(
        ([product]) => {
            addItem(product, data?.products?.nodes.find(item => item.id == product))
            options.splice(options.findIndex(item => item.value == product), 1)
            setOptions([...options])
            setFilter('')
        },
        [options]
    )

    useEffect(
        () => {
            setOptions((data?.products?.nodes || []).filter(
                product => !items.some($product => product.id == $product.product.value)
            ).map(renderOption))
        },
        [items]
    )

    useEffect(
        () => {
            searchProducts({
                variables: {
                    query: filter
                }
            })
        },
        [debouncedFilter]
    )

    useEffect(
        () => {
            if (data?.products?.nodes) {
                setOptions(
                    data.products.nodes.filter(
                        product => !items.some($product => product.id == $product.product.value)
                    ).map(renderOption)
                )
            }
        },
        [data]
    )

    return (
        <Autocomplete
            options={options}
            selected={[]}
            loading={loading}
            onSelect={onSelect}
            textField={
                <Autocomplete.TextField
                    value={filter}
                    onChange={setFilter}
                    prefix={<Icon source={SearchMinor} color="base" />}
                    placeholder="Search products"
                />
            }
        />
    )
}
