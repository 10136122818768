import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Form,
  FormLayout,
  Layout,
  Page,
  TextField,
} from "@shopify/polaris";
import { useField, useForm } from "@shopify/react-form";
import { useHistory, useParams } from "react-router";
import CollectionField from "../utils/CollectionField";
import ImageForm from "../utils/ImageForm";
import useQueryParams from '../utils/useQueryParams'

const COLLECTION_FRAGMENT = gql`
  fragment CollectionFragment on Collection {
    id
    name
    description
  }
`;
const COLLECTION_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  query($id: ID!) {
    collection(id: $id) {
      ...CollectionFragment
      parent {
        ...CollectionFragment
      }
    }
  }
`;

export default function Collection() {
  const history = useHistory();
  const { id } = useParams();
  const query = useQueryParams();
  const { data } = useQuery(COLLECTION_QUERY, { variables: { id } });
  const [mutateCollection, { loading: mutating }] = useMutation(
    gql`
      ${COLLECTION_FRAGMENT}
      mutation($collection: CollectionInput!, $id: ID) {
        collection(collection: $collection, id: $id) {
          ...CollectionFragment
          parent {
            ...CollectionFragment
          }
        }
      }`
  );
  // const { fields: metafields } = useDynamicList({
  //   list: ["title", "description"].map((key) => ({
  //     namespace: "seo",
  //     key,
  //     type: "string",
  //     value: "",
  //   })),
  // });
  const { fields, submit } = useForm({
    fields: {
      name: useField(data?.collection?.name),
      description: useField(data?.collection?.description),
      image: useField(data?.collection?.image?.id),
      parent: useField(data?.collection?.parent?.id || query.get('parent')),
      //   metafields
    },
    onSubmit: async (collection) => {
      return mutateCollection({
        variables: { collection, id },
      })
        .then((response) => {
          // makeClean();
          history.replace("/collections/" + response.data.collection.id);
          return { status: "success" };
        })
        .catch((error) => ({ status: "fail", errors: [error.message] }));
    },
  });
  return (
    <Page
      title="Collection"
      primaryAction={{ content: 'Save', onAction: submit, loading: mutating }}
      breadcrumbs={[data?.collection?.parent?.id ? { content: data.collection.parent.name, url: `/collections?parent=${data.collection.parent.id}` } : { content: "Collections", url: "/collections" }]}
    >
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Form onSubmit={submit}>
              <FormLayout>
                <TextField type="text" label="Name" {...fields.name} />
                <TextField
                  type="text"
                  label="Description"
                  multiline
                  {...fields.description}
                />
              </FormLayout>
            </Form>
          </Card>
        </Layout.Section>
        <Layout.Section secondary>
          <Card title="Image" sectioned subdued>
            <ImageForm {...fields.image} />
          </Card>
          <Card title="Parent" sectioned subdued>
            <CollectionField  {...fields.parent} allowMultiple={false} />
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
