import { Card, FormLayout, Layout, Page, TextField, ContextualSaveBar, Form, Banner, } from "@shopify/polaris";
import { useField, useForm } from '@shopify/react-form';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";

const ADD_CUSTOMER_MUTATION = gql`mutation addCustomer($customer: CustomerInput!, $address: AddressInput) {
    customer(customer: $customer, address: $address) {
      id
    }
  }
`

export default function NewCustomer() {

    const history = useHistory();

    const [addCustomer, { loading }] = useMutation(ADD_CUSTOMER_MUTATION);

    const {
        fields,
        submit,
        dirty,
        reset,
        submitErrors,
    } = useForm({
        fields: {
            firstName: useField(),
            lastName: useField(),
            email: useField(),
            phone: useField(),
            address: useField(),
            city: useField(),
            postalCode: useField(),
        },
        onSubmit: async values => {
            console.log('onSubmit', values)
            return addCustomer({
                variables: {
                    customer: {
                        ...values,
                        address: undefined,
                        city: undefined,
                        postalCode: undefined,
                    },
                    address: { ...values, company: '' }
                }
            }).then(
                response => {
                    history.push(`/customers/${response.data.customer.id}`)
                    return { status: 'success' }
                }
            ).catch(
                error => {
                    return { status: 'fail', errors: [error] }
                }
            )
        },
    });

    return (
        <Page title="Add new customer" breadcrumbs={[{ content: 'Customers', url: '/customers' }]}>
            {
                dirty && <ContextualSaveBar
                    message="Unsaved changes"
                    saveAction={{
                        onAction: submit,
                        loading: loading,
                        disabled: false
                    }}
                    discardAction={{
                        onAction: reset,
                    }}
                />
            }
            <Form onSubmit={submit}>
                <Layout>
                    {
                        submitErrors.length > 0 ? (
                            <Layout.Section>
                                <Banner status="critical">
                                    <p>There were some issues with your form submission:</p>
                                    <ul>
                                        {submitErrors.map(({ message }, index) => {
                                            return <li key={`${message}${index}`}>{message}</li>;
                                        })}
                                    </ul>
                                </Banner>
                            </Layout.Section>
                        ) : null
                    }
                    <Layout.Section />
                    <Layout.AnnotatedSection
                        title="Customer overview"
                    >
                        <Card sectioned>
                            <FormLayout>
                                <FormLayout.Group>
                                    <TextField label="First name" {...fields.firstName} />
                                    <TextField label="Last name" {...fields.lastName} />
                                </FormLayout.Group>
                                <TextField type="tel" label="Phone" {...fields.phone} />
                                <TextField type="email" label="Email" {...fields.email} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                    <Layout.AnnotatedSection
                        title="Address"
                        description="The primary address of this customer"
                    >
                        <Card sectioned>
                            <FormLayout>
                                <TextField label="Address" multiline {...fields.address} />
                                <TextField label="City" {...fields.city} />
                                <TextField label="Postal code" {...fields.postalCode} />
                            </FormLayout>
                        </Card>
                    </Layout.AnnotatedSection>
                </Layout>
            </Form>
        </Page>
    )
}