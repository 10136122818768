import { DropZone, Stack } from "@shopify/polaris";

export default function ImageForm({ value, onChange }) {
    return (
        <Stack vertical>
            <Stack.Item>
                <div>
                    <DropZone accept="image/*" type="image" onDrop={() => {}}>
                        <DropZone.FileUpload />
                    </DropZone>
                </div>
            </Stack.Item>
        </Stack>
    )
}