import {
  Form,
  FormLayout,
  Modal,
  OptionList,
  TextField,
} from "@shopify/polaris";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import {
  JsonForms,
  withJsonFormsControlProps,
  withJsonFormsLayoutProps,
  ResolvedJsonFormsDispatch,
} from "@jsonforms/react";
import { vanillaCells, vanillaRenderers } from "@jsonforms/vanilla-renderers";
import {
  rankWith,
  schemaTypeIs,
  uiTypeIs,
  isEnumControl,
  or
} from "@jsonforms/core"
import BlockEditor from "./BlockEditor";

export { vanillaCells }
export const polarisRenderers = [
  ...vanillaRenderers,
  {
    renderer: withJsonFormsLayoutProps(
      ({ uischema, schema, path, renderers, cells }) => {
        return (
          <FormLayout>
            {uischema.elements.map((child, index) => (
              <ResolvedJsonFormsDispatch
                key={`${path}-${index}`}
                renderers={renderers}
                cells={cells}
                uischema={child}
                schema={schema}
                path={path}
              />
            ))}
          </FormLayout>
        );
      }
    ),
    tester: rankWith(1000, uiTypeIs("FormLayout")),
  },
  {
    renderer: withJsonFormsLayoutProps(
      ({ uischema, schema, path, renderers, cells }) => {
        return (
          <FormLayout>
            <FormLayout.Group>
              {uischema.elements.map((child, index) => (
                <ResolvedJsonFormsDispatch
                  key={`${path}-${index}`}
                  renderers={renderers}
                  cells={cells}
                  uischema={child}
                  schema={schema}
                  path={path}
                />
              ))}
            </FormLayout.Group>
          </FormLayout>
        );
      }
    ),
    tester: rankWith(1000, uiTypeIs("FormLayoutGroup")),
  },
  {
    renderer: withJsonFormsControlProps(
      ({ data, handleChange, uischema, path, schema, ...args }) => {
        const [selected, onChange] = useState(
          (uischema.options?.allowMultiple ? data : [data]) || []
        );
        return (
          <OptionList
            title={uischema.label}
            allowMultiple={uischema.options?.allowMultiple}
            label={uischema.label}
            options={schema.enum.map((value, index) => ({
              value,
              label: schema.labels[index],
            }))}
            selected={selected}
            onChange={(value) => {
              onChange(value);
              handleChange(
                path,
                uischema.options?.allowMultiple ? value : value[0]
              );
            }}
          />
        );
      }
    ),
    tester: rankWith(
      3000, //increase rank as needed
      isEnumControl
    ),
  },
  {
    renderer: withJsonFormsControlProps(
      ({ data, handleChange, uischema, path, schema, ...args }) => {
        if (uischema.options?.isRich)
          return (
            <FormLayout>
              <BlockEditor data={data} onChange={(value) => handleChange(path, value)} />
            </FormLayout>
          )
        return (
          <TextField
            label={uischema.label}
            value={data}
            multiline={uischema.options?.multiline}
            onChange={(value) => handleChange(path, value)}
          />
        );
      }
    ),
    tester: rankWith(
      3000, //increase rank as needed
      or(schemaTypeIs('string'), schemaTypeIs('object'))
    ),
  },
];

export const META_QUERY = gql`query($entity: MetaEntity!) {
  metas(entity: $entity) {
    nodes {
      id
      entity
      title
      namespace
      control
      ui
    }
  }
}
`

export default function MetaModal({ meta, id, setMetaActives }) {
  const [active, setActive] = useState(false);
  const [data, onChange] = useState({});
  const toggleActive = useCallback(() => setActive(!active), [active]);
  const [mutate, { loading: mutating }] = useMutation(gql`mutation($id: ID, $metafields: [MetaFieldInput!]) {
    ${meta.entity}(id: $id, ${meta.entity}: { metafields: $metafields }) {
      ... on Entity {
        id
      }
    }
  }`)
  const { loading, data: { [meta.entity]: { data: state } } = { [meta.entity]: { data: {} } } } = useQuery(gql`query($id: ID!, $namespace: String!) {
    ${meta.entity}(id: $id) {
      ... on Product {
        data: metafield(namespace: $namespace)
      }
    }
  }`, { variables: { id, namespace: meta.namespace }, fetchPolicy: 'no-cache' })

  useEffect(() => {
    setMetaActives(
      metaActives => ({
        ...metaActives,
        [meta.id]: toggleActive
      })
    )
  }, []);
  useEffect(
    () => {
      onChange(state)
    },
    [loading]
  )

  const save = useCallback(
    () => {
      const metafields = Object.entries(data).map(
        ([key, value]) => ({
          namespace: meta.namespace,
          key,
          type: meta.control.properties[key].type,
          value
        })
      )
      mutate({
        variables: {
          id,
          metafields
        }
      }).then(
        () => {
          toggleActive()
        }
      )
    }
  )

  return (
    <Modal
      open={active}
      onClose={toggleActive}
      title={meta.title}
      primaryAction={{
        content: "Save",
        onAction: save,
        loading: loading || mutating
      }}
      secondaryActions={[
        {
          content: "Close",
          onAction: toggleActive,
        },
      ]}
    >
      <Form>
        {/* <Modal.Section>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </Modal.Section> */}
        <Modal.Section>
          <JsonForms
            schema={meta.control}
            uischema={meta.ui}
            data={data}
            onChange={({ errors, data }) => onChange(data)}
            renderers={polarisRenderers}
            cells={vanillaCells}
          />
        </Modal.Section>
      </Form>
    </Modal>
  );
}
