import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Checkbox,
  ContextualSaveBar,
  Form,
  FormLayout,
  Layout,
  OptionList,
  Page,
  ResourceItem,
  ResourceList,
  Select,
  SkeletonBodyText,
  SkeletonPage,
  SkeletonThumbnail,
  Stack,
  TextField,
  TextStyle,
  Thumbnail,
} from "@shopify/polaris";
import {
  asChoiceField,
  useDynamicList,
  useField,
  useForm,
  getValues,
} from "@shopify/react-form";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CollectionField from "../utils/CollectionField";
import ImagesForm from "../utils/ImagesForm";
import MetaModal from "../utils/MetaModal";
import { META_QUERY } from "../utils/MetaModal";
import ProductField from "../utils/ProductField";
import TagField from "../utils/TagField";
import { useAuth } from "../Access/Auth.hook";
import { Helmet } from "react-helmet";

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on ProductUnion {
    ... on Entity {
      id
    }
    ... on Referable {
      reference
    }
    ... on Product {
      status
      type
      name
      description
      images {
        id
      }
      price
      compareAtPrice
      brand {
        id
      }
      collections {
        id
      }
      weight {
        value
        unit
      }
      dimension {
        length
        width
        height
        unit
      }
      sku
      barcode
      inventoryTrack
      inventoryPolicy
      inventoryLocations {
        id
      }
      tags
      ... on Pack {
        items {
          product {
            id
            name
            image {
              id
            }
          }
          quantity
          discount
          price
          compareAtPrice
        }
      }
    }
  }
`;

function PackItem({ field, recalculatePrice }) {
  const { data } = useQuery(
    gql`
      query($id: ID!) {
        product(id: $id) {
          ... on Entity {
            id
          }
          ... on Product {
            name
            image {
              id
              url
            }
            price
            compareAtPrice
          }
        }
      }
    `,
    { variables: { id: field.product.value } }
  );

  useEffect(() => {
    const price = parseFloat(field.compareAtPrice.value || field.price.value);
    const discount = parseFloat(field.discount.value);
    field.price.onChange(
      (price - (discount < 1 ? (price * discount) / 100 : discount)).toString()
    );
    recalculatePrice();
  }, [field.discount.value, field.quantity.value]);

  useEffect(() => {
    recalculatePrice();
  }, [field.price.value]);

  return (
    <ResourceItem
      verticalAlignment="center"
      key={field.product.value}
      id={field.product.value}
      media={
        !data ? (
          <SkeletonThumbnail size="medium" />
        ) : (
          <Thumbnail source={data?.product?.image?.url} />
        )
      }
    >
      {!data ? (
        <SkeletonBodyText />
      ) : (
        <Layout sectioned>
          <h3 style={{ marginBottom: "10px" }}>
            <Stack>
              <Stack.Item fill>
                <TextStyle variation="strong">{data?.product?.name}</TextStyle>
              </Stack.Item>
              <Stack.Item>
                <Button size="slim" onClick={() => { }}>
                  Remove
                </Button>
              </Stack.Item>
            </Stack>
          </h3>
          <Stack alignment="center">
            <Stack.Item>
              <div style={{ width: "10rem" }}>
                <TextField label="Quantity" type="number" {...field.quantity} />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div style={{ width: "10rem" }}>
                <TextField label="Discount" type="number" {...field.discount} />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div style={{ width: "10rem" }}>
                <TextField
                  label="Price"
                  readOnly
                  type="number"
                  {...field.price}
                />
              </div>
            </Stack.Item>
            <Stack.Item>
              <div style={{ width: "10rem" }}>
                <TextField
                  label="Original price"
                  readOnly
                  type="number"
                  {...field.compareAtPrice}
                />
              </div>
            </Stack.Item>
          </Stack>
        </Layout>
      )}
    </ResourceItem>
  );
}

export function Product({ id, product }) {
  const auth = useAuth()

  const { data: { brands: { nodes: brands } } = { brands: { nodes: [] } } } = useQuery(gql`query{brands{nodes{value:id label:name}}}`)

  const history = useHistory();
  const [mutateProduct, { loading: mutating }] = useMutation(
    gql`
      ${PRODUCT_FRAGMENT}
      mutation($product: ProductInput!, $id: ID) {
        product(product: $product, id: $id) {
          ...ProductFragment
        }
      }
    `
  );
  const { fields: items, addItem, removeItem } = useDynamicList(
    {
      list: (product?.items || []).map(({ product, ...item }) => ({
        product: product.id,
        quantity: item.quantity.toString(),
        discount: item.discount.toString(),
        price: item.price.toString(),
        compareAtPrice: item.compareAtPrice.toString(),
      })),
    },
    ({ product, item }) => ({
      product,
      quantity: "1",
      discount: "0",
      price: item.price.toString(),
      compareAtPrice: (item.compareAtPrice || item.price).toFixed(2),
    })
  );
  const { fields, submit, dirty, reset, makeClean } = useForm({
    fields: {
      status: useField(product?.status || "draft"),
      type: useField(product?.type || "nonVariant"),
      name: useField(product?.name),
      // description: useField(product?.description),
      description: useField(product?.description),
      images: useField(
        useRef(new Array(...(product?.images?.map(({ id }) => id) || [])))
          .current
      ),
      price: useField(product?.price?.toFixed(2)),
      compareAtPrice: useField(product?.compareAtPrice?.toFixed(2)),
      collections: useField(
        useRef(new Array(...(product?.collections?.map(({ id }) => id) || [])))
          .current
      ),
      sku: useField(product?.sku),
      barcode: useField(product?.barcode),
      inventoryTrack: useField(product?.inventoryTrack),
      inventoryPolicy: useField(product?.inventoryPolicy === "continue"),
      inventoryLocations: useField(
        useRef(
          new Array(...(product?.inventoryLocations?.map(({ id }) => id) || []))
        ).current
      ),
      weight: {
        value: useField(product?.weight?.value),
        unit: useField(product?.weight?.unit || "grams"),
      },
      dimension: {
        length: useField(product?.dimension?.length),
        width: useField(product?.dimension?.width),
        height: useField(product?.dimension?.height),
        unit: useField(product?.dimension?.unit || "centimeter"),
      },
      brand: useField(product?.brand?.id),
      tags: useField(useRef(new Array(...(product?.tags || []))).current),
      items,
    },
    onSubmit: async (product) => {
      console.log("onSubmit", product);
      ["price", "compareAtPrice"].forEach(
        (key) => (product[key] = parseFloat(product[key]))
      );
      product.items.forEach((item) =>
        ["quantity", "discount", "price", "compareAtPrice"].forEach(
          (key) => (item[key] = parseFloat(item[key]))
        )
      );
      product.inventoryPolicy = product.inventoryPolicy ? "continue" : "deny";
      return mutateProduct({
        variables: { product, id },
      })
        .then((response) => {
          makeClean();
          history.replace("/products/" + response.data.product.id);
          return { status: "success" };
        })
        .catch((error) => ({ status: "fail", errors: [error.message] }));
    },
  });
  const {
    data: { locations: { nodes: locations } } = { locations: { nodes: [] } },
  } = useQuery(
    gql`
      {
        locations {
          nodes {
            id
            name
          }
        }
      }
    `
  );

  useEffect(() => {
    fields.inventoryLocations.onChange(
      fields.inventoryTrack.value ? fields.inventoryLocations.defaultValue : []
    );
  }, [fields.inventoryTrack.value, fields.inventoryLocations.defaultValue]);

  const [calculatedPackPrice, setCalculatedPackPrice] = useState('')

  const recalculatePrice = useCallback(() => {
    const _items = Object.values(getValues(items));
    console.log("Recalculate price", _items);
    fields.compareAtPrice.onChange(
      _items
        .reduce(
          (total, { compareAtPrice, quantity }) =>
            total + parseFloat(compareAtPrice) * parseInt(quantity),
          0
        )
        .toFixed(2)
    );
    setCalculatedPackPrice(
      _items
        .reduce(
          (total, { price, quantity }) =>
            total + parseFloat(price) * parseInt(quantity),
          0
        )
        .toFixed(2)
    );
  }, [items]);
  const { data: { metas: { nodes: metas } } = { metas: { nodes: [] } } } = useQuery(META_QUERY, { variables: { entity: 'product' } })

  const [metaActives, setMetaActives] = useState({});
  const metaMarkups = product?.id && metas.map(
    meta => {
      const markup = <MetaModal
        key={meta.id}
        {...{
          meta,
          id: product?.id,
          setMetaActives
        }}
      />
      return markup
    }
  )

  const title = product?.name || "Add product";
  return (
    <Page
      title={title}
      breadcrumbs={[{ content: "Products", onAction: () => history.goBack() }]}
      primaryAction={{
        content: "Save",
        onAction: submit,
      }}
      actionGroups={[
        {
          title: "More actions",
          actions: [
            {
              content: "SEO Settings",
            },
            // {
            //   content: "Meta",
            //   onAction: () => setMetaActive(true)
            // },
            ...metas.map(
              (meta) => ({
                content: meta.title,
                onAction: () => metaActives[meta.id]()
              })
            )
          ],
        },
      ].filter(() => product?.id)}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {
        metaMarkups
      }
      {/* <pre>{JSON.stringify(metas, null, 2)}</pre> */}
      {dirty && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: submit,
            loading: mutating,
            disabled: false,
          }}
          discardAction={{
            onAction: reset,
            // discardConfirmationModal: true
          }}
        />
      )}
      <Form onSubmit={submit}>
        <Layout>
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <TextField label="Name" {...fields.name}></TextField>
                <TextField
                  label="Description"
                  multiline
                  {...fields.description}
                ></TextField>
              </FormLayout>
            </Card>
            <Card title="Media" sectioned>
              <ImagesForm {...fields.images} />
            </Card>
            {fields.type.value == "pack" && auth.hasRole('product_edit') && (
              <Card title="Items">
                <Card.Section>
                  <ProductField
                    {...{
                      items,
                      addItem: (product, _product) => {
                        addItem({ product, item: _product });
                        console.log(product, _product);
                        // recalculatePrice()
                      },
                      type: "nonVariant",
                    }}
                  />
                </Card.Section>
                <Card.Section flush>
                  <ResourceList
                    items={items}
                    renderItem={(item, index) => {
                      return (
                        <PackItem
                          key={item.product.value}
                          id={item.product.value}
                          field={item}
                          recalculatePrice={recalculatePrice}
                        />
                      );
                      return (
                        <ResourceItem
                          verticalAlignment="center"
                          key={item.product.value}
                          id={item.product.value}
                        // media={<Thumbnail source={item.product.image.url} />}
                        >
                          <PackItem field={item} key={item.product.value} />
                        </ResourceItem>
                      );
                    }}
                  />
                </Card.Section>
                {
                  fields.price.value != calculatedPackPrice && <Card.Section title="Calculated price is different that pack price">
                    <FormLayout>
                      <TextField
                        prefix="MAD"
                        type="number"
                        label="Calculated price"
                        value={calculatedPackPrice}
                        readOnly
                      />
                      <Button primary onClick={() => fields.price.onChange(calculatedPackPrice)}>Use calculated price</Button>
                    </FormLayout>
                  </Card.Section>
                }
              </Card>
            )}
            {auth.hasRole('product_edit') && <Card title="Pricing" sectioned>
              <FormLayout>
                <FormLayout.Group>
                  <TextField
                    prefix="MAD"
                    type="number"
                    label="Price"
                    {...fields.price}
                  // disabled={fields.type.value == "pack"}
                  />
                  <TextField
                    prefix="MAD"
                    type="number"
                    label="Compare at price"
                    {...fields.compareAtPrice}
                  // disabled={fields.type.value == "pack"}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>}
            {auth.hasRole('product_edit') && <Card title="Inventory">
              <Card.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      label="SKU (Stock Keeping Unit)"
                      {...fields.sku}
                    />
                    <TextField
                      label="Barcode (ISBN, UPC, GTIN, etc.)"
                      {...fields.barcode}
                    />
                  </FormLayout.Group>
                  <Checkbox
                    label="Track quantity"
                    {...asChoiceField(fields.inventoryTrack)}
                  />
                  {fields.inventoryTrack.value && (
                    <Checkbox
                      label="Continue selling when out of stock"
                      {...asChoiceField(fields.inventoryPolicy)}
                    />
                  )}
                </FormLayout>
              </Card.Section>
              {fields.inventoryTrack.value && (
                <Card.Section title="Locations">
                  <OptionList
                    onChange={fields.inventoryLocations.onChange}
                    options={locations.map((location) => ({
                      value: location.id,
                      label: location.name,
                    }))}
                    selected={fields.inventoryLocations.value}
                    allowMultiple
                  />
                  {/* <DataTable
                                    columnContentTypes={['text', 'text']}
                                    headings={['Location', 'Availability']}
                                    rows={
                                        locations.map(
                                            location => [location.name, 'Hi']
                                        )
                                    }
                                /> */}
                </Card.Section>
              )}
            </Card>
            }
            {auth.hasRole('product_edit') && <Card title="Shipping" sectioned>
              <FormLayout>
                <TextField
                  label="Weight"
                  type="number"
                  connectedRight={
                    <Select
                      label="Weight unit"
                      labelHidden
                      options={["grams", "kilograms", "ounces", "pounds"]}
                      {...fields.weight.unit}
                    />
                  }
                  {...fields.weight.value}
                // connectedRight={<Button>Submit</Button>}
                />
                <FormLayout.Group condensed>
                  <TextField
                    label="Length"
                    type="number"
                    {...fields.dimension.length}
                  />
                  <TextField
                    label="Width"
                    type="number"
                    {...fields.dimension.width}
                  />
                  <TextField
                    label="Height"
                    type="number"
                    {...fields.dimension.height}
                  />
                  <Select
                    label="Unit"
                    options={["centimeter", "meter", "inch", "feet"]}
                    {...fields.dimension.unit}
                  />
                </FormLayout.Group>
              </FormLayout>
            </Card>
            }
          </Layout.Section>
          <Layout.Section secondary>
            {!product?.id && (
              <Card title="Product type" sectioned>
                <Select
                  options={[
                    { label: "Item", value: "nonVariant" },
                    { label: "Pack", value: "pack" },
                  ]}
                  {...fields.type}
                ></Select>
              </Card>
            )}
            <Card title="Product status" sectioned>
              <Select
                options={[
                  { label: "Draft", value: "draft" },
                  { label: "Active", value: "active" },
                  { label: "Archived", value: "archived" },
                ]}
                {...fields.status} />
            </Card>
            <Card title="Organization" subdued>
              <Card.Section title="Collections">
                <FormLayout>
                  <CollectionField {...fields.collections} />
                  {/* <TextField helpText="Add this product to a collection so it’s easy to find in your store." /> */}
                </FormLayout>
              </Card.Section>
              <Card.Section title="Brand">
                <FormLayout>
                  <Select options={brands} {...fields.brand} />
                </FormLayout>
              </Card.Section>
              <Card.Section title="Tags">
                <FormLayout>
                  <TagField entity="product" {...fields.tags} />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
      </Form>
    </Page>
  );
}

export default function ProductPage() {
  const { id } = useParams();
  const [fetch, { data, loading, error, called }] = useLazyQuery(
    gql`
      ${PRODUCT_FRAGMENT}
      query($id: ID!) {
        product(id: $id) {
          ...ProductFragment
        }
      }
    `
  );

  useEffect(
    () => {
      if (id != 'new') {
        fetch({ variables: { id } })
      }
    },
    [id]
  )

  if (error) {
    return <div>Error</div>
  }

  if (loading && id != 'new') {
    return <SkeletonPage primaryAction secondaryActions={2}>
      <Helmet>
        <title>Loading...</title>
      </Helmet>
    </SkeletonPage>;
  } else {
    return <Product id={id} product={data?.product} />;
  }
}
