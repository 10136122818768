import { useMutation, useQuery } from "@apollo/client";
import { Card, Form, FormLayout, Loading, Page, Select, SkeletonBodyText, SkeletonPage, Tabs, TextField } from "@shopify/polaris";
import { useField, useForm } from "@shopify/react-form";
import gql from "graphql-tag";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import AsyncEditor from '../utils/AsyncEditor'
import { ENUM_LABEL_VALUE } from "../utils/ENUM_LABEL_VALUE.query";

const META_FRAGMENT = gql`fragment MetaFragment on Meta{id entity title namespace control ui}`
const META_QUERY = gql`${META_FRAGMENT}query($id:ID!){meta(id:$id){...MetaFragment}}`
const META_MUTATION = gql`${META_FRAGMENT}mutation($id:ID,$meta:MetaInput!){meta(meta:$meta,id:$id){...MetaFragment}}`

export function Meta({ meta, id }) {
    const [mutate] = useMutation(META_MUTATION)
    const history = useHistory();

    const {
        fields,
        submit
    } = useForm({
        fields: {
            entity: useField(meta?.entity),
            namespace: useField(meta?.namespace),
            title: useField(meta?.title),
            control: useField(meta?.control),
            ui: useField(meta?.ui),
        },
        async onSubmit(meta) {
            await mutate({
                variables: {
                    id,
                    meta
                }
            }).then(
                response => {
                    history.replace("/settings/metas/" + response.data.meta.id);
                }
            )
            return {
                status: 'success'
            }
        }
    })
    const { data: { __type: { enumValues: entities } } = { __type: { enumValues: [] } } } = useQuery(ENUM_LABEL_VALUE, { variables: { type: 'MetaEntity' } })
    const tabs = [
        {
            id: 'control',
            content: 'Controls',
            editor: <AsyncEditor {...fields.control} />,
        },
        {
            id: 'ui',
            content: 'UI',
            editor: <AsyncEditor {...fields.ui} />,
        },
    ]
    const [selected, onSelect] = useState(0)

    return (
        <Page title="Meta" narrowWidth primaryAction={{ content: 'Save', onAction: submit }} breadcrumbs={[{ content: 'Metas', url: '/settings/metas' }]} >
            <Card sectioned>
                <Form>
                    <FormLayout>
                        <Select disabled={!!meta.id} label="Entity" options={entities.map(({ value, label }) => ({ value, label: label || value }))} {...fields.entity} />
                        <TextField disabled={!!meta.id} label="Namespace" {...fields.namespace} />
                        <TextField label="Title" {...fields.title} />
                        <Tabs tabs={tabs} selected={selected} onSelect={onSelect}>
                            <Card.Section fullWidth>
                                {tabs[selected].editor}
                            </Card.Section>
                        </Tabs>
                    </FormLayout>
                </Form>
            </Card>
        </Page>
    )
}

export default function MetaPage() {
    const { id } = useParams();
    const { data: { meta } = { meta: { control: {}, ui: {} } }, loading } = useQuery(META_QUERY, { variables: { id } })



    if (loading) {
        return (
            <SkeletonPage primaryAction narrowWidth>
                <Loading />
                <Card sectioned>
                    <SkeletonBodyText />
                </Card>
            </SkeletonPage>
        )
    } else {
        return (
            <Meta {...{ meta, id }} />
        )
    }
}