import {useCallback, useRef} from 'react';

import { useDirty, useReset, useSubmit, validateAll, makeCleanFields } from "@shopify/react-form";

export default function useForm({
    fields,
    onSubmit,
    makeCleanAfterSubmit = false,
}) {
    const dirty = useDirty(fields);
    const basicReset = useReset(fields);
    const { submit, submitting, errors, setErrors } = useSubmit(
        onSubmit,
        fields,
        makeCleanAfterSubmit,
    );

    const reset = useCallback(() => {
        setErrors([]);
        basicReset();
    }, [basicReset, setErrors]);

    const fieldsRef = useRef(fields);
    fieldsRef.current = fields;

    const validate = useCallback(() => {
        return validateAll(fieldsRef.current);
    }, [fieldsRef]);

    const makeClean = useCallback(() => makeCleanFields(fieldsRef.current), [
        fieldsRef,
    ]);

    return {
        fields,
        dirty,
        submitting,
        submit,
        reset,
        validate,
        makeClean,
        submitErrors: errors,
        setErrors
    };
}