import { gql, useQuery } from "@apollo/client";
import { Autocomplete, Layout, Stack, Tag } from "@shopify/polaris";
import { remove as removeDiacritics } from "diacritics";
import { useCallback, useEffect, useState } from "react";

const COLLECTIONS_QUERY = gql`
  {
    collections(limit: 100) {
      nodes {
        id
        value: id
        label: name
      }
    }
  }
`;

export default function CollectionField({ value, onChange, allowMultiple = true }) {
  const [filter, setFilter] = useState(null);
  const { data, loading } = useQuery(COLLECTIONS_QUERY);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState([]);

  const onSelect = useCallback((value) => {
    setSelected(value);
    onChange(allowMultiple ? value : value[0]);
  });

  const filterOptions = useCallback((value) => {
    setFilter(value);
    if (!data) return;
    if (!value) {
      setOptions(data.collections.nodes);
    } else {
      const filterRegex = new RegExp(removeDiacritics(value), "i");
      const filtered = data.collections.nodes.filter((option) =>
        removeDiacritics(option.label).match(filterRegex)
      );
      setOptions(filtered);
    }
  });

  const removeCollection = useCallback(
    (id) => () => {
      const options = [...selected];
      options.splice(options.indexOf(id), 1);
      onSelect(options);
    },
    [selected, onSelect]
  );

  useEffect(() => !loading && setOptions(data?.collections?.nodes || []), [
    loading,
  ]);

  useEffect(() => value && onSelect(allowMultiple ? value : [value]), [value]);

  const collectionsMarkup = !loading && selected.map((option) => (
    <Tag key={option} onRemove={removeCollection(option)}>
      {
        data.collections?.nodes.find((collection) => collection.value === option)
          .label
      }
    </Tag>
  ));

  return (
    <Layout sectioned>
      <Stack vertical>
        {
          <Autocomplete
            allowMultiple={allowMultiple}
            options={options}
            textField={
              <Autocomplete.TextField
                value={filter}
                onChange={filterOptions}
                placeholder={options.map(({ label }) => label).join(", ")}
                // prefix={<Icon source={SearchMinor} color="base" />}
              />
            }
            selected={selected}
            onSelect={onSelect}
            loading={loading}
            // listTitle="Suggested Collections"
          />
        }
        {collectionsMarkup}
      </Stack>
    </Layout>
  );
}
